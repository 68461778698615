import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import api from "api";

import uploadImages from "utils/uploadImages";
import uploadFiles from "utils/uploadFiles";
import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import TaskForm from "components/TaskForm";
import UserContext from "contexts/UserContext";

const EditTaskModal = ({
	show,
	disableModal,
	task,
	setActiveTask,
	setTasks,
}) => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [formData, setFormData, handleInput] = useForm(task);
	const [images, setImages] = useState(
		formData.image ? formData.image.split(",") : []
	);
	const [documents, setDocuments] = useState(
		formData.file ? formData.file.split(",") : []
	);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const formSubmitButton = useRef();

	useEffect(() => {
		setFormData(task);
	}, [task, show, setFormData]);

	const saveTask = async e => {
		e.preventDefault();

		if (saving) return;
		setSaving(true);
		setError(null);

		let [fileNamesStr, error] = await uploadImages(images, formData.image);
		if (error) {
			setSaving(false);
			return setError(error);
		}
		let [docFileNamesStr, filesUploadError] = await uploadFiles(
			documents,
			formData.file
		);
		if (filesUploadError) {
			setSaving(false);
			return setError(error);
		}

		let assignedUsers = formData?.channel?.assigners
			? formData.channel.assigners.map(assigner => assigner.user_id)
			: [];

		if (formData.creator_id) {
			if (!assignedUsers.includes(formData.creator_id)) {
				assignedUsers.push(formData.creator_id);
			}
		}

		if (!assignedUsers.includes(user.userData.id)) {
			assignedUsers.push(user.userData.id);
		}

		if (formData.user_id && !assignedUsers.includes(formData.user_id))
			assignedUsers.push(formData.user_id);

		const taskObj = {
			...formData,
			image: fileNamesStr,
			file: docFileNamesStr,
			assigned_users: assignedUsers,
			user_id: formData.user_id || -1,
		};

		assignedUsers = assignedUsers.filter(user => user !== null && user > 0);

		api.put("/task", {
			task_id: taskObj.id,
			...taskObj,
			assigned_users: assignedUsers,
		})
			.then(response => {
				setSaving(false);
				if (response.data.error) setError(response.data.error);
				else {
					setActiveTask(response.data.data);
					setTasks(tasks => {
						return {
							...tasks,
							list: tasks.list.map(task =>
								task.id === taskObj.id
									? response.data.data
									: task
							),
						};
					});
					disableModal();
				}
			})
			.finally(() => setSaving(false));
	};

	return (
		<Modal
			title={t("edit_task_navigation_title")}
			show={show}
			disableModal={disableModal}
			footerButtons={
				<Button
					onClick={() => formSubmitButton.current.click()}
					loading={saving}
					className="btn-primary"
				>
					{t("edit_task_update_button_text")}
				</Button>
			}
		>
			<TaskForm
				onSubmit={saveTask}
				handleInput={handleInput}
				formData={formData}
				formSubmitButton={formSubmitButton}
				setImages={setImages}
				setDocuments={setDocuments}
				error={error}
			/>
		</Modal>
	);
};

export default EditTaskModal;
