import React, { useState, useRef, useContext } from "react";
import { NotificationManager } from "react-notifications";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import api, { baseURL } from "api";

import UserContext from "contexts/UserContext";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import DynamicSelect from "components/DynamicSelect";
import uploadImages from "utils/uploadImages";
import uploadFiles from "utils/uploadFiles";
import {
	getSubchannelTitle,
	sendAndProcessMessageRequest,
} from "../pages/Chat/utils";
import ChatContext from "contexts/ChatContext";

const ZoomedImage = ({
	url,
	filename = "image",
	setZoomed,
	channelId,
	zIndex = 10002,
	isDocument,
	downloadFile,
}) => {
	const { t } = useTranslation();
	const [chat, chatDispatch] = useContext(ChatContext);
	const [user] = useContext(UserContext);
	const [forwardModal, setForwardModal] = useState(false);
	const [usersToForward, setUsersToForward] = useState([]);
	const [forwarding, setForwarding] = useState(false);
	const image = useRef();

	const forwardFile = async () => {
		setForwarding(true);

		let files = [];

		let url = image.current.src;
		if (isDocument) url = `${baseURL}/uploads/${filename}`;

		await fetch(url)
			.then(res => res.blob())
			.then(blob => files.push(new File([blob], filename)));

		await usersToForward.forEach(async subchannel_id => {
			let [fileNamesStr, error] = isDocument
				? await uploadFiles(files)
				: await uploadImages(files);

			if (error) {
				NotificationManager.error(error);
			}

			await sendAndProcessMessageRequest({
				api,
				chat,
				chatDispatch,
				channel_id: subchannel_id,
				requestData: {
					image: !isDocument ? fileNamesStr : null,
					document: isDocument ? fileNamesStr : null,
				},
				user,
			});
		});

		setForwarding(false);
		setForwardModal(false);
		setUsersToForward([]);
	};

	const isNewDocument = isDocument && filename?.name?.length > 0;

	return createPortal(
		<div className="zoomed-img" style={{ zIndex }}>
			<div className="hover-backdrop"></div>
			{!isNewDocument && (
				<div className="zoomed-img--actions">
					<button onClick={() => downloadFile(image.current?.src)}>
						<i className="fas fa-download"></i>{" "}
						{t("image_download")}
					</button>
					<button onClick={() => setForwardModal(true)}>
						<i className="fas fa-share"></i> {t("image_forward")}
					</button>
					<i
						onClick={() => setZoomed(false)}
						className="cursor-pointer fas fa-times"
					></i>
				</div>
			)}
			<div className="img-wrapper">
				<OutsideClickHandler
					onOutsideClick={e =>
						!(e.path || (e.composedPath && e.composedPath())).find(
							el =>
								el.nodeName === "BUTTON" ||
								el.className?.includes("forward-modal")
						) && setZoomed(false)
					}
				>
					<img ref={image} src={url} alt={filename} />
				</OutsideClickHandler>
			</div>

			<Modal
				title={t("image_forward")}
				className="forward-modal"
				show={forwardModal}
				disableModal={() => setForwardModal(false)}
				zIndex={zIndex + 1}
				footerButtons={
					<Button loading={forwarding} onClick={forwardFile}>
						{t("image_forward")}
					</Button>
				}
			>
				<DynamicSelect
					endpoint="/sub_channels"
					labelProperty="channel_id"
					placeholder={t("edit_task_select_user")}
					onChange={subchannels =>
						setUsersToForward(
							subchannels
								? subchannels.map(user => user.value)
								: []
						)
					}
					staticPrepare={subchannels =>
						subchannels
							.filter(
								subchannel =>
									subchannel.channel_id !== channelId
							)
							.map(subchannel => ({
								value: subchannel.channel_id,
								label: getSubchannelTitle(subchannel, user),
							}))
					}
					isMulti
				/>
			</Modal>
		</div>,
		document.getElementById("modal")
	);
};

export default ZoomedImage;
