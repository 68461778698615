import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import api from "api";
import { TASKS_PAGE_PATH } from "routes";

import classNames from "classnames";
import IsMobileContext from "contexts/IsMobileContext";
import TasksContext from "contexts/TasksContext";
import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
// import Select from "components/core/Select";
import Alert from "components/core/Alert";
import Task from "./Task";
import TaskInternal from "./TaskInternal";
// import LoadingWrapper from "components/core/LoadingWrapper";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";
import MeasurableDiv from "components/MeasurableDiv";
import Spinner from "components/core/Spinner";
import TaskStatusFilter from "./TaskStatusFilter";
import useBottomScrollListener from "hooks/useBottomScrollListener";

const limit = 20;

const Tasks = ({ match }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const [tasks, setTasks] = useContext(TasksContext);
	const [fetching, setFetching] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [activeTask, setActiveTask] = useState(null);
	const [tasksType, setTasksType] = useState("ACTIVE");
	const [error, setError] = useState(null);
	const [page, setPage] = useState(0);
	const [refetch, setRefetch] = useState(0);
	const [initiallyFetched, setInitiallyFetched] = useState(false);

	const phone = useContext(IsMobileContext);

	const lastLocation = useRef(null);

	const [scrollRef, , setNextPage] = useBottomScrollListener(() =>
		setPage(page => page + 1)
	);

	useEffect(() => {
		if (
			lastLocation.current?.includes(TASKS_PAGE_PATH) &&
			lastLocation.current !== TASKS_PAGE_PATH &&
			location.pathname === TASKS_PAGE_PATH
		)
			setActiveTask(null);
		lastLocation.current = location.pathname;
	}, [location.pathname]);

	useEffect(() => {
		setPage(0);
		setTasks({ list: [], additionalOffset: 0 });
		setRefetch(refetch => refetch + 1);
	}, [searchText, tasksType, setPage, setTasks]);

	useEffect(() => {
		// if (fetching) return; // by not fetching results when it's already happening, some search inputs will be skipped when input is faster than request, that's why this line should be commented out
		setFetching(true);

		api.get("/tasks", {
			params: {
				offset: page * limit + tasks.additionalOffset,
				limit: limit + 1,
				query: searchText,
				filter_by: tasksType,
			},
		})
			.then(response => {
				if (response.data.error) setError(response.data.error);
				else {
					if (response.data.data.length > limit) {
						setTasks(tasks => ({
							...tasks,
							list: [
								...(page === 0 ? [] : tasks.list),
								...response.data.data.slice(0, -1),
							],
						}));
						setNextPage(true);
					} else {
						setTasks(tasks => ({
							...tasks,
							list: [
								...(page === 0 ? [] : tasks.list),
								...response.data.data,
							],
						}));
						if (response.data.data.length < limit)
							setNextPage(false);
						else setNextPage(true);
					}
				}
			})
			.catch(() => setError(t("app_server_error")))
			.finally(() => {
				setFetching(false);
				if (!initiallyFetched) setInitiallyFetched(true);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, refetch, page]);

	useEffect(() => {
		if (!tasks?.list?.length) return;
		if (!match.params.id) return;
		if (activeTask?.id === parseInt(match.params.id)) return;
		const foundTask = tasks.list.find(
			task => task.id === parseInt(match.params.id)
		);
		if (!foundTask) {
			// UZLOADINAM TASKA PER REQUESTA
			api.get(`/tasks`, {
				params: { task_id: parseInt(match.params.id) },
			}).then(response => {
				if (response.data.error) return;
				if (!response.data.data.length) return;
				setActiveTask(response.data.data[0]);
			});
		} else setActiveTask(foundTask);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params.id, tasks]);

	const MAIN_STATUSES = [
		{
			value: "ACCEPTED",
			name: "tasks_accepted",
		},
		{
			value: "NOT_ACCEPTED",
			name: "tasks_not_accepted",
		},
		{
			value: "FINISHED",
			name: "tasks_finished",
		},
		{
			value: "DECLINED",
			name: "tasks_declined",
		},
	];

	const taskTypeOptions = [
		{
			value: "ACTIVE",
			label: t("tasks_active"),
		},
		{
			value: "ALL",
			label: t("tasks_all_tasks"),
		},
		...MAIN_STATUSES.map(status => ({
			...status,
			label: t(status.name),
		})),
	];

	return (
		<div className="d-flex">
			<div
				className={classNames(
					{ "col-4": !phone },
					{ "col-12": phone },
					{ "d-none": phone && activeTask },
					"p-0"
				)}
			>
				<MeasurableDiv name="tasksFilter">
					<div className="px-3">
						<div className="mb-3">
							<Select
								className="dynamic-select task-type-select"
								classNamePrefix="dynamic-select"
								value={taskTypeOptions.find(
									option => option.value === tasksType
								)}
								name="task_type"
								onChange={v => setTasksType(v.value)}
								options={taskTypeOptions}
								isSearchable={false}
							/>
							{/* <Select
								name="task_type"
								onChange={e => setTasksType(e.target.value)}
								selected={tasksType}
								initialChange={false}
								className="task-type-select"
								options={[
									{
										value: "ACTIVE",
										name: t("tasks_active"),
									},
									{
										value: "ALL",
										name: t("tasks_all_tasks"),
									},
									...MAIN_STATUSES.map(status => ({
										...status,
										name: t(status.name),
									})),
								]}
							/> */}
						</div>

						<InputWrapper>
							<Input
								onChange={e => setSearchText(e.target.value)}
								placeholder={`${t("app_search")}...`}
								icon={<i className="fas fa-search"></i>}
								dark
							/>
						</InputWrapper>

						{/* {MAIN_STATUSES.map(status => (
							<div
								key={status.value}
								onClick={() => setTasksType(status.value)}
								className="cursor-pointer mr-2"
							>
								<TaskStatus status={status.name} />
							</div>
						))} */}
						<table className="w-100 mt-2">
							<tbody>
								<tr className="my-1">
									<td>
										<TaskStatusFilter
											status={MAIN_STATUSES[0]}
											setTasksType={setTasksType}
										/>
									</td>
									<td>
										<TaskStatusFilter
											status={MAIN_STATUSES[1]}
											setTasksType={setTasksType}
										/>
									</td>
								</tr>
								<tr className="my-1">
									<td>
										<TaskStatusFilter
											status={MAIN_STATUSES[2]}
											setTasksType={setTasksType}
										/>
									</td>
									<td>
										<TaskStatusFilter
											status={MAIN_STATUSES[3]}
											setTasksType={setTasksType}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="divider divider--horizontal mt-2"></div>
				</MeasurableDiv>

				<FullScreenHeightWrapper
					elements={["tasksFilter"]}
					ref={scrollRef}
					className="px-3"
				>
					{/* <LoadingWrapper fetched={initiallyFetched} size={80}> */}
					<div className="tasks-list">
						{error ? (
							<Alert success={false}>{t(error)}</Alert>
						) : (
							<>
								{!tasks.list.length && !fetching && (
									<p className="text-center h4 mt-4">
										{t("tasks_list_empty")}
									</p>
								)}
								{tasks.list.map(task => (
									<Task
										key={task.id}
										data={task}
										active={
											activeTask &&
											task.id === activeTask.id
										}
										onSelect={() => setActiveTask(task)}
									/>
								))}
								{fetching && (
									<Spinner size={80} color="#343a40" />
								)}
							</>
						)}
					</div>
					{/* </LoadingWrapper> */}
				</FullScreenHeightWrapper>
			</div>
			{((activeTask && phone) || !phone) && (
				<FullScreenHeightWrapper
					className={classNames(
						{ "col-8": !phone },
						{ "col-12": phone },
						"p-0 bg-secondary"
					)}
				>
					{!activeTask ? (
						<h3 className="d-flex align-items-center justify-content-center h-100 text-center">
							{t("tasks_select")}
						</h3>
					) : (
						<TaskInternal
							activeTask={activeTask}
							setActiveTask={setActiveTask}
							setTasks={setTasks}
						/>
					)}
				</FullScreenHeightWrapper>
			)}
		</div>
	);
};

export default Tasks;
