import React, { useState, useEffect } from "react";

import FileUpload from "./FileUpload";

const FilesUpload = ({ defaultFilesStr, files, setFiles, type = "images" }) => {
	const [fileUploads, setFileUploads] = useState([null, null, null]);

	useEffect(() => {
		if (!files) return;
		setFileUploads([
			...files,
			...new Array(3 - (files.length % 3)).map(() => null),
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (defaultFilesStr) {
			let imagesArr = defaultFilesStr ? defaultFilesStr.split(",") : [];

			setFileUploads([
				...imagesArr,
				...new Array(3 - (imagesArr.length % 3)).map(() => null),
			]);
		}
	}, [defaultFilesStr]);

	useEffect(() => {
		const realUploads = fileUploads.filter(imageUpload =>
			Boolean(imageUpload)
		);
		setFiles(realUploads);

		if (realUploads.length === fileUploads.length)
			setFileUploads(fileUploads => [...fileUploads, null, null, null]);
		else if (
			fileUploads.length - realUploads.length >= 4 &&
			![...fileUploads].reverse().find((v, i) => i < 3 && Boolean(v))
		) {
			setFileUploads(fileUploads => {
				return fileUploads.filter((_, i) => i < fileUploads.length - 3);
			});
		}
	}, [fileUploads, setFiles]);

	const onChange = (index, newValue) => {
		setFileUploads(fileUploads =>
			fileUploads.map((value, i) => (index === i ? newValue : value))
		);
	};

	return (
		<div
			className={
				"images-upload d-flex justify-content-between flex-wrap w-100 " +
				type
			}
		>
			{fileUploads.map((fileUpload, i) => (
				<FileUpload
					key={i}
					fileInfo={fileUpload}
					onChange={newValue => onChange(i, newValue)}
					type={type}
				/>
			))}
		</div>
	);
};

export default FilesUpload;
