import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import { CHAT_PAGE_PATH } from "routes";

import ChatContext from "contexts/ChatContext";
import ChatTemplate from "pages/Chat/ChatTemplate";
import FileDrop from "components/core/FileDrop";
import SubHeader from "components/SubHeader";
import ChatMessages from "./ChatMessages";
import MessageInput from "./MessageInput";
import { getSubchannelTitle } from "./utils";

const Template = memo(props => {
	return (
		<>
			<SubHeader className="px-4">
				<h3 className="m-0 font-weight-normal">
					<Link
						to={CHAT_PAGE_PATH}
						className="go-back-button border-right empty-button mr-3"
					>
						<i className="fas fa-arrow-left"></i>
					</Link>
					{getSubchannelTitle(props.subchannel, props.user)}
				</h3>
			</SubHeader>
			<FileDrop onDrop={props.onDrop}>
				<ChatMessages
					setPage={props.setPage}
					fetchingMessages={props.fetchingMessages}
					activeSubchannel={props.subchannel}
					setForwardModal={props.setForwardModal}
				/>
				<MessageInput
					sendMessage={props.sendMessage}
					messageInput={props.messageInput}
					setMessageInput={props.setMessageInput}
					openUploadImagesModal={props.openUploadImagesModal}
					openUploadFilesModal={props.openUploadFilesModal}
				/>
			</FileDrop>
		</>
	);
});

const InternalChat = memo(({ createChat }) => {
	const [chat] = useContext(ChatContext);

	return (
		<ChatTemplate
			subchannel={chat.activeSubchannels[0]}
			createChat={createChat}
			Template={Template}
		/>
	);
});

export default InternalChat;
