import { useState, useCallback } from "react";

function useForm(defaultData = {}) {
	const [formData, setFormData] = useState(defaultData);

	const handleInput = useCallback(
		e => {
			const event = { ...e };
			setFormData(formData => ({
				...formData,
				[event.target.name]: event.target.value,
			}));
		},
		[setFormData]
	);

	return [formData, setFormData, handleInput];
}

export default useForm;
