import api from "api";

async function uploadFiles(files, initialFilesStr = "") {
	const initialFiles =
		!initialFilesStr || !initialFilesStr.length
			? []
			: initialFilesStr.split(",");

	const newFiles = files.filter(file => !file.length);
	const presentFiles = files.filter(file => initialFiles.includes(file));
	const filesToDelete = initialFiles.filter(
		fileFileName => !files.includes(fileFileName)
	);

	let filenames = [...presentFiles];
	let error = [];

	for (let index in newFiles) {
		const compressedImage = new File(
			[newFiles[index]],
			newFiles[index].name.replace(/ /g, "_")
		);
		const fileUploadData = new FormData();
		fileUploadData.append("file", compressedImage);

		await api
			.post("/fileupload", fileUploadData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				if (response.data.error) error.push(response.data.error);
				filenames.push(response.data.filename);
			});
	}

	for (let index in filesToDelete) {
		await api
			.delete("/delete_image", {
				params: { image_name: filesToDelete[index] },
			})
			.then(() => {
				// if (response.data.error)
			});
	}

	return [filenames.join(","), error.length ? error[0] : null];
}

export default uploadFiles;
