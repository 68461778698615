import React, { useContext } from "react";

import UserContext from "contexts/UserContext";
import ObjectIcon from "components/User";

const UserItem = ({ user, onClick, onDeleteClick }) => {
	const [userCtx] = useContext(UserContext);

	const onInternalDeleteClick = e => {
		e.stopPropagation();
		onDeleteClick();
	};

	return (
		<div onClick={onClick} className="user-row">
			<ObjectIcon size={4} iconWrapperClassName="mr-3">
				<div className="d-flex flex-column">
					<h3>{user.full_name}</h3>
					<span className="username">{user.username}</span>
				</div>
			</ObjectIcon>
			<div>
				{userCtx.userData.role === "SUPER" && (
					<div
						className="d-inline mr-4"
						onClick={onInternalDeleteClick}
					>
						<i className="fas fa-trash-alt"></i>
					</div>
				)}
				<i className="fas fa-long-arrow-alt-right"></i>
			</div>
		</div>
	);
};

export default UserItem;
