import React, { useState, useEffect, useContext, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api, { baseURL } from "api";
import CONSTS from "consts";
import getAudioMetaDataCallback from "utils/getAudioMetaDataCallback";

import { splitByLastDot } from "pages/Chat/utils";
import ChatContext from "contexts/ChatContext";
import UserContext from "contexts/UserContext";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import FilesUpload from "components/FilesUpload";
import uploadImages from "utils/uploadImages";
import uploadFiles from "utils/uploadFiles";
import ForwardMessageModal from "./ForwardMessageModal";
import { sendAndProcessMessageRequest } from "./utils";

const soundExtensions = ["mp3", "wmv", "mpa"];

const ChatTemplate = memo(({ subchannel, Template, createChat }) => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [chat, chatDispatch] = useContext(ChatContext);

	const [messageInput, setMessageInput] = useState("");
	const [sending, setSending] = useState(false);
	const [page, setPage] = useState(0);
	const [isNextPageAvailable, setIsNextPageAvailable] = useState(true);
	const [fetchingMessages, setFetchingMessages] = useState(false);
	const [uploadImagesModal, setUploadImagesModal] = useState(false);
	const [images, setImages] = useState([]);
	const [uploadFilesModal, setUploadFilesModal] = useState(false);
	const [files, setFiles] = useState([]);
	const [uploadingFiles, setUploadingFiles] = useState(false);

	const sendImagesButton = useRef();
	const sendFilesButton = useRef();

	const [forwardModal, setForwardModal] = useState(false);
	const [forwarding, setForwarding] = useState(false);

	let additionalOffset = subchannel?.additionalOffset || 0;

	useEffect(() => {
		chatDispatch({
			type: "set_additional_offset_callback",
			payload: { channel_id: subchannel.channel_id, callback: () => 0 },
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
		additionalOffset = 0; // SET NEW VALUE TO SEPARATE VALUE SO IT CHANGES BEFORE GOING INTO SECOND USEEFFECT
		setPage(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subchannel.channel_id]);

	useEffect(() => {
		if (!subchannel) return;
		if (!isNextPageAvailable && page !== 0) return;
		const channel_id = subchannel.channel_id;
		if (!channel_id) return;
		setFetchingMessages(true);
		api.get("/chats", {
			params: {
				channel_id,
				offset: page * CONSTS.CHAT_MESSAGES_LIMIT + additionalOffset,
				limit: CONSTS.CHAT_MESSAGES_LIMIT + 1,
			},
		}).then(response => {
			if (!response.data.error) {
				if (page === 0 && response.data.data?.length)
					chatDispatch({
						type: "mark_seen_messages",
						payload: {
							channel_id,
							seen: response.data.seen,
							lastMessage: response.data.data[0],
							user,
						},
					});
				const reversedEls = [...response.data.data].reverse();
				const withoutFirstEl = reversedEls;
				withoutFirstEl.shift();
				const msgs =
					response.data.data > CONSTS.CHAT_MESSAGES_LIMIT
						? withoutFirstEl
						: reversedEls;
				if (page === 0) {
					chatDispatch({
						type: "set_messages",
						payload: { channel_id, messages: msgs },
					});
				} else
					chatDispatch({
						type: "set_messages_callback",
						payload: {
							channel_id,
							callback: messages => [...msgs, ...messages],
						},
					});

				if (response.data.data.length > CONSTS.CHAT_MESSAGES_LIMIT) {
					setIsNextPageAvailable(true);
				} else setIsNextPageAvailable(false);
			}

			setFetchingMessages(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subchannel.channel_id, page]);

	useEffect(() => {
		setUploadingFiles(false);
		setFiles([]);
	}, [uploadFilesModal]);

	const sendImages = async e => {
		if (e) e.preventDefault();

		if (uploadingFiles) return;
		if (!images.length) return;
		setUploadingFiles(true);
		let [imgFileNamesStr, error] = await uploadImages(images);
		setUploadingFiles(false);
		if (error) return NotificationManager.error(error);

		sendMessage({ imgFileNamesStr });
		setImages([]);
		setUploadImagesModal(false);
		setUploadingFiles(false);
	};

	const sendFiles = async e => {
		if (e) e.preventDefault();
		if (!files.length) return;
		if (uploadingFiles) return;
		setUploadingFiles(true);

		let imagesToUpload = files.filter(file => {
			return file.type.split("/")[0] === "image";
		});
		let filesToUpload = files.filter(
			file => file.type.split("/")[0] !== "image"
		);

		let [imgFileNamesStr, imageUploadError] = await uploadImages(
			imagesToUpload
		);
		if (imageUploadError) {
			setUploadingFiles(false);
			return NotificationManager.error(imageUploadError);
		}
		let [docFileNamesStr, fileUploadError] = await uploadFiles(
			filesToUpload
		);
		if (fileUploadError) {
			setUploadingFiles(false);
			return NotificationManager.error(fileUploadError);
		}

		const filenames = docFileNamesStr.split(",");
		docFileNamesStr = filenames
			.filter(
				filename =>
					!soundExtensions.includes(splitByLastDot(filename)[1])
			)
			.join(",");
		filenames // voiceFileNamesStr
			.filter(filename =>
				soundExtensions.includes(splitByLastDot(filename)[1])
			)
			.forEach(voiceFileName => {
				getAudioMetaDataCallback(
					`${baseURL}/uploads/${voiceFileName}`,
					metadata => {
						sendMessage({
							voiceFileName,
							audioFileMiliseconds: metadata.duration * 1000,
						});
					}
				);
			});

		sendMessage({ docFileNamesStr });
		sendMessage({ imgFileNamesStr });
		setFiles([]);
		setUploadFilesModal(false);
		setUploadingFiles(false);
	};

	const forwardMessage = async channels => {
		setForwarding(true);

		for await (let channel of channels) {
			await sendMessage({
				channelId: channel,
				messageValue: forwardModal,
			});
		}

		setForwardModal(false);

		setForwarding(false);
	};

	const sendSubchannelMessage = obj => {
		sendMessage(obj);
	};

	const sendMessage = async ({
		e,
		channelId = subchannel.channel_id,
		messageValue = messageInput,
		imgFileNamesStr = null,
		docFileNamesStr = null,
		voiceFileName = null,
		audioFileMiliseconds = null,
	}) => {
		if (e) e.preventDefault();

		if (!channelId) return;
		if (
			!messageValue.trim().length &&
			!imgFileNamesStr &&
			!docFileNamesStr &&
			!voiceFileName
		)
			return;

		if (sending) return;
		setSending(true);

		let valueToSend =
			imgFileNamesStr || docFileNamesStr
				? null
				: voiceFileName
				? audioFileMiliseconds
				: messageValue;

		let channel_id = channelId;
		if (!channel_id) {
			channel_id = await createChat();
		}

		sendAndProcessMessageRequest({
			api,
			chat,
			chatDispatch,
			channel_id,
			requestData: {
				value: valueToSend,
				image: imgFileNamesStr,
				document: docFileNamesStr,
				sound: voiceFileName,
			},
			user,
			setMessageInput,
			setSending,
		});
	};

	const closeChat = () => {
		chatDispatch({
			type: "filter_active_subchannels",
			payload: {
				callback: activeSubchannels =>
					activeSubchannels.filter(
						activeSubchannel =>
							activeSubchannel.channel_id !==
							subchannel.channel_id
					),
			},
		});
	};

	const openUploadImagesModal = () => setUploadImagesModal(true);
	const openUploadFilesModal = () => setUploadFilesModal(true);

	const onDrop = e => {
		let dt = e.dataTransfer;
		let droppedFiles = dt.files;

		if (!droppedFiles.length) return;

		// if (type === "images") {
		// 	const type = files[0].type.split("/");
		// 	if (type[0] !== "image") return;
		// }

		// const extension = files[0].name.split(".")[1];
		// if (!validDocuments.includes(extension)) return;

		setFiles([...droppedFiles]);

		openUploadFilesModal();
	};

	return (
		<>
			<Template
				user={user}
				subchannel={subchannel}
				closeChat={closeChat}
				setPage={setPage}
				fetchingMessages={fetchingMessages}
				sendMessage={e => sendSubchannelMessage({ e })}
				messageInput={messageInput}
				setMessageInput={setMessageInput}
				openUploadImagesModal={openUploadImagesModal}
				openUploadFilesModal={openUploadFilesModal}
				onDrop={onDrop}
				setForwardModal={setForwardModal}
			/>
			<Modal
				show={uploadImagesModal}
				title={t("attachments")}
				disableModal={() => setUploadImagesModal(false)}
				footerButtons={
					<Button
						onClick={() => sendImagesButton.current.click()}
						loading={uploadingFiles}
						className="btn-primary"
					>
						{t("date_picker_ok")}
					</Button>
				}
			>
				<form onSubmit={sendImages}>
					<FilesUpload setFiles={setImages} />

					{/* {error && <Alert success={false}>{error}</Alert>} */}

					<button ref={sendImagesButton} className="d-none">
						Submit form
					</button>
				</form>
			</Modal>
			<Modal
				show={uploadFilesModal}
				title={t("files")}
				disableModal={() => setUploadFilesModal(false)}
				footerButtons={
					<Button
						onClick={() => sendFilesButton.current.click()}
						loading={uploadingFiles}
						className="btn-primary"
					>
						{t("date_picker_ok")}
					</Button>
				}
			>
				<form onSubmit={sendFiles}>
					<FilesUpload
						type="documents"
						files={files}
						setFiles={setFiles}
					/>

					{/* {error && <Alert success={false}>{error}</Alert>} */}

					<button ref={sendFilesButton} className="d-none">
						Submit form
					</button>
				</form>
			</Modal>
			<ForwardMessageModal
				forwardModal={Boolean(forwardModal)}
				setForwardModal={setForwardModal}
				forwarding={forwarding}
				user={user}
				channelId={subchannel.channel_id}
				forwardCallback={forwardMessage}
			/>
		</>
	);
});

export default ChatTemplate;
