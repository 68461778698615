import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { setAuthToken } from "api";

import UserContext from "contexts/UserContext";
import OptionButton from "./OptionButton";
import LanguageSelectButton from "./LanguageSelectButton";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";

const Settings = () => {
	const { t } = useTranslation();
	const [user, setUser] = useContext(UserContext);

	const logout = () => {
		localStorage.removeItem("token");
		setAuthToken(null);
		setUser({ authorized: false, userData: {} });
	};

	return (
		<FullScreenHeightWrapper className="settings-wrapper bg-secondary">
			<div className="container-fluid">
				<div className="d-flex justify-content-between">
					<h2>{t("menu_settings")}</h2>
					<h3>{user.userData.full_name}</h3>
				</div>

				{user?.userData?.role !== "WORKER" ? (
					<>
						<div className="options-list">
							<OptionButton
								title={t("settings_manage_users")}
								to="/settings/users"
								iconClassName="fas fa-users"
							/>
							<div className="sized-box"></div>
							<LanguageSelectButton />
						</div>
						<div className="options-list">
							<OptionButton
								title={t("settings_manage_objects")}
								to="/settings/objects"
								iconClassName="fas fa-bars"
							/>
							<div className="sized-box"></div>
							<OptionButton
								onClick={logout}
								title={t("menu_log_out")}
								iconClassName="fas fa-sign-out-alt"
								arrowRight={false}
							/>
						</div>
					</>
				) : (
					<>
						<div className="options-list">
							<LanguageSelectButton />
							<div className="sized-box"></div>
							<OptionButton
								onClick={logout}
								title={t("menu_log_out")}
								iconClassName="fas fa-sign-out-alt"
								arrowRight={false}
							/>
						</div>
					</>
				)}
				<div className="d-flex justify-content-between mt-5">
					<div>{t("app_name")}</div>
					<span>App 1.0.6</span>
				</div>
			</div>
		</FullScreenHeightWrapper>
	);
};

export default Settings;
