import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "api";

import LoadingWrapper from "components/core/LoadingWrapper";
import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
import Alert from "components/core/Alert";
import PlusCircle from "components/PlusCircle";
import CreateUserModal from "./CreateUserModal";
import EditUserModal from "./EditUserModal";
import SubHeader from "components/SubHeader";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";
import UserItem from "./UserItem";
import DeleteUserModal from "./DeleteUserModal";

const Users = () => {
	const { t } = useTranslation();
	const [fetching, setFetching] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [error, setError] = useState(null);
	const [createUserModal, setCreateUserModal] = useState(false);
	const [editUserModal, setEditUserModal] = useState(null);
	const [deleteUserModal, setDeleteUserModal] = useState(null);

	useEffect(() => {
		api.get("/users", { params: { query: searchText } })
			.then(response => {
				if (response.data.error) setError(response.data.error);
				else {
					setUsers(response.data.data);
					setFilteredUsers(response.data.data);
				}
			})
			.catch(() => setError(t("app_server_error")))
			.finally(() => setFetching(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	useEffect(() => {
		setFilteredUsers(
			users.filter(
				user =>
					user.full_name
						?.toLowerCase()
						.includes(searchText.toLowerCase()) ||
					user.username
						?.toLowerCase()
						.includes(searchText.toLowerCase())
			)
		);
	}, [users, searchText]);

	return (
		<div className="users-wrapper">
			<SubHeader className="container-fluid d-flex justify-content-between pt-0">
				<div className="d-flex align-items-center">
					<Link to="/settings" className="btn btn-back">
						<i className="fas fa-long-arrow-alt-left"></i>
					</Link>
					<h2>{t("select_user_navigation_title")}</h2>
				</div>
				<div className="d-flex align-items-center">
					<InputWrapper>
						<Input
							onChange={e => setSearchText(e.target.value)}
							placeholder={`${t("app_search")}...`}
							icon={<i className="fas fa-search"></i>}
							dark
						/>
					</InputWrapper>
					<PlusCircle
						onClick={() => setCreateUserModal(true)}
						className="ml-2"
					/>
				</div>
			</SubHeader>
			<FullScreenHeightWrapper className="bg-secondary">
				<div className="users-list container-fluid">
					<LoadingWrapper fetched={!fetching} size={120}>
						<>
							{error ? (
								<Alert success={false}>{error}</Alert>
							) : (
								<>
									{!filteredUsers.length && (
										<h4 className="text-center py-3">
											{t("app_empty")}
										</h4>
									)}

									{filteredUsers.map(user => (
										<UserItem
											key={user.id}
											user={user}
											onClick={() =>
												setEditUserModal(user)
											}
											onDeleteClick={() =>
												setDeleteUserModal(user.id)
											}
										/>
									))}
								</>
							)}
						</>
					</LoadingWrapper>
				</div>
			</FullScreenHeightWrapper>

			<CreateUserModal
				show={createUserModal}
				disableModal={() => setCreateUserModal(false)}
				setUsers={setUsers}
			/>

			<EditUserModal
				show={editUserModal}
				disableModal={() => setEditUserModal(null)}
				setUsers={setUsers}
				user={editUserModal}
			/>

			<DeleteUserModal
				userID={deleteUserModal}
				setModal={setDeleteUserModal}
				setUsers={setUsers}
			/>
		</div>
	);
};

export default Users;
