import { useState, useRef, useEffect } from "react";

function useBottomScrollListener(callback) {
	const scrollRef = useRef();
	const [nextPage, setNextPage] = useState(false);

	useEffect(() => {
		if (!callback || !scrollRef.current) return;

		let el = scrollRef.current;
		const scrollCallback = e => {
			if (!nextPage) return;
			if (el.scrollTop >= el.scrollHeight - el.clientHeight - 30) {
				setNextPage(false);
				callback();
			}
		};
		scrollCallback();
		el.addEventListener("scroll", scrollCallback);

		return function cleanup() {
			el.removeEventListener("scroll", scrollCallback);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextPage, scrollRef]);

	return [scrollRef, nextPage, setNextPage];
}

export default useBottomScrollListener;
