import React, { useState, useContext } from "react";
import api, { setAuthToken } from "api";
import classNames from "classnames";

import UserContext from "contexts/UserContext";
// import { VALIDATOR_REQUIRE } from "utils/validators";
import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
// import ValidableInput from "components/core/ValidableInput";
import Button from "components/core/Button";
import Alert from "components/core/Alert";

const Login = () => {
	const [wasValidated, setWasValidated] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState({});

	const [, setUser] = useContext(UserContext);

	const login = e => {
		e.preventDefault();
		setWasValidated(false);

		if (loading) return;
		setLoading(true);
		setResponse({});

		api.post(
			"/login",
			{ username, password },
			{
				headers: {
					token:
						"dGVzdC5hY2NvdW50QHN1cnasdfh2cZleXBhbC5jb206dG9rZW4tQUFfSHZYWDBBMVkxWDExMTEzWlYzdasdlopqklasjExY1hYOWRlLTlmNDAtMzQ5MGZkNDWY5",
				},
			}
		)
			.then(response => {
				if (response.data.error) {
					setResponse({
						success: false,
						message: response.data.error,
					});
				} else {
					localStorage.setItem(
						"token",
						response.data.data.accessToken
					);
					setAuthToken(response.data.data.accessToken);
					setResponse({
						success: true,
						message: "Succesfully logged in",
					});
					setUser({
						authorized: true,
						userData: { username, password },
					});
				}
			})
			.catch(() =>
				setResponse({ success: false, message: "Unknown error" })
			)
			.finally(() => setLoading(false));
	};

	return (
		<form
			onSubmit={login}
			className={classNames([
				"mt-5",
				"form-signin",
				{ "was-validated": wasValidated },
			])}
		>
			<h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>

			<InputWrapper
				// label="Username"
				htmlFor="inputUsername"
				className="mb-3"
			>
				<Input
					// errorText="Please enter username"
					// validators={[VALIDATOR_REQUIRE()]}
					onChange={e => setUsername(e.target.value)}
					name="email"
					id="inputUsername"
					placeholder="Username"
					required={true}
					autoFocus={true}
				/>
			</InputWrapper>

			<InputWrapper
				// label="Password"
				htmlFor="inputPassword"
				className="mb-3"
			>
				<Input
					defaultValue={password}
					onChange={e => setPassword(e.target.value)}
					type="password"
					name="password"
					id="inputPassword"
					placeholder="Password"
					required={true}
				/>
			</InputWrapper>

			{response.message && (
				<Alert
					onClose={() => setResponse({})}
					success={response.success}
				>
					{response.message}
				</Alert>
			)}

			<Button
				onClick={() => setWasValidated(false)}
				loading={loading}
				className="btn-lg btn-primary btn-block"
				type="submit"
			>
				Sign in
			</Button>
		</form>
	);
};

export default Login;
