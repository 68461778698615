import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { baseURL } from "api";

import ZoomableImage from "components/ZoomableImage";
import FileDrop from "components/core/FileDrop";
import { splitByLastDot } from "pages/Chat/utils";

const toBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

const validDocuments = [
	"mp3",
	"mpa",
	"wav",
	"wma",
	"7z",
	"rar",
	"zip",
	"csv",
	"xml",
	"gif",
	"jpeg",
	"jpg",
	"png",
	"svg",
	"ppt",
	"pptx",
	"odp",
	"pps",
	"ods",
	"xls",
	"xlsm",
	"xlsx",
	"3gp",
	"3g2",
	"m4v",
	"mp4",
	"mpg",
	"mpeg",
	"wmv",
	"doc",
	"docx",
	"odt",
	"pdf",
	"rtf",
	"txt",
];
const acceptDocumentsString = validDocuments
	.map(extension => `.${extension}`)
	.join(",");

const FileUpload = ({ fileInfo, onChange, type = "images" }) => {
	const { t } = useTranslation();
	const [file, setFile] = useState(null);
	const imageInput = useRef();

	useEffect(() => {
		if (!fileInfo) return setFile(null);
		if (fileInfo.length) {
			setFile({ url: `${baseURL}/uploads/${fileInfo}` });
		} else {
			(async () => {
				const base64 = await toBase64(fileInfo);
				setFile({ file: fileInfo, url: base64 });
			})();
		}
	}, [fileInfo]);

	const onUpload = async file => {
		const base64 = await toBase64(file);
		const fileObj = { file: file, url: base64 };
		setFile(fileObj);
		onChange(fileObj.file);
	};

	const onImageInputChange = event => {
		const e = { ...event };
		if (!e.target.files.length) return setFile(null);
		onUpload(e.target.files[0]);
	};

	const onImageDrop = e => {
		let dt = e.dataTransfer;
		let files = dt.files;

		if (!files.length) return;

		if (type === "images") {
			const type = files[0].type.split("/");
			if (type[0] !== "image") return;
		}

		const extension = splitByLastDot(files[0].name)[1];
		if (!validDocuments.includes(extension)) return;

		onUpload(files[0]);
	};

	const removeUpload = () => {
		imageInput.current.value = "";
		setFile(null);
		onChange(null);
	};

	if (type === "documents") {
		return (
			<FileDrop onDrop={onImageDrop} className="image-upload col-4 mb-2">
				{Boolean(file) ? (
					<div className="image-upload__uploaded-image">
						<div
							className="image-upload__uploaded-image__remove_button"
							onClick={removeUpload}
						>
							<i className="fas fa-times"></i>
						</div>
						<ZoomableImage
							filename={fileInfo}
							zIndex={10003}
							document={true}
							column={true}
						/>
					</div>
				) : (
					<div
						className="image-upload__upload-placeholder"
						onClick={() => imageInput.current.click()}
					>
						<i className="h1 far fa-file-alt"></i>
						<span>+ {t("add_document")}</span>
					</div>
				)}
				<input
					className="d-none"
					type="file"
					onChange={onImageInputChange}
					ref={imageInput}
					accept={acceptDocumentsString}
				/>
			</FileDrop>
		);
	}

	return (
		<FileDrop onDrop={onImageDrop} className="image-upload col-4 mb-2">
			{Boolean(file) ? (
				<div className="image-upload__uploaded-image">
					<div
						className="image-upload__uploaded-image__remove_button"
						onClick={removeUpload}
					>
						<i className="fas fa-times"></i>
					</div>
					<ZoomableImage
						filename={fileInfo}
						imageURL={file.url}
						zIndex={10003}
					/>
				</div>
			) : (
				<div
					className="image-upload__upload-placeholder"
					onClick={() => imageInput.current.click()}
				>
					<img
						src={
							require("assets/img/image_placeholder.svg")?.default
						}
						alt="Upload placeholder"
					/>
					<span>+ {t("add_photo")}</span>
				</div>
			)}
			<input
				className="d-none"
				type="file"
				onChange={onImageInputChange}
				ref={imageInput}
				accept="image/*"
			/>
		</FileDrop>
	);
};

export default FileUpload;
