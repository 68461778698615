import React from "react";
import { useTranslation } from "react-i18next";

import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
import AutoTextArea from "components/AutoTextArea";
import Alert from "components/core/Alert";
import DatePicker from "react-datepicker";
import DynamicSelect from "./DynamicSelect";
import FilesUpload from "components/FilesUpload";

const TaskForm = ({
	onSubmit,
	handleInput,
	formSubmitButton,
	formData,
	error,
	setImages,
	setDocuments,
}) => {
	const { t } = useTranslation();

	return (
		<form onSubmit={onSubmit}>
			<div className="d-flex mb-3">
				<div className="col-6 pl-0 pr-3">
					<InputWrapper
						label={t("edit_task_title")}
						htmlFor="title"
						// className="mb-3"
					>
						<Input
							name="title"
							placeholder={t("input_label_name")}
							onChange={handleInput}
							defaultValue={formData?.title}
							required
							light
						/>
					</InputWrapper>
				</div>
				<div className="col-6 pr-0 pl-3">
					<InputWrapper
						label={t("edit_task_description")}
						htmlFor="description"
						// className="mb-3"
					>
						<AutoTextArea
							// name="description"
							wrapperClassName="w-100"
							className="form-control input-light"
							placeholder={t("input_label_description")}
							onChange={e =>
								handleInput({
									target: {
										name: "description",
										value: e.target.value,
									},
								})
							}
							defaultValue={formData?.description}
							// rows="1"
							required
							// light
						/>
					</InputWrapper>
				</div>
			</div>

			{/* <div className="divider divider--horizontal mb-3"></div> */}

			<div className="d-flex mb-3">
				<div className="col-6 pl-0 pr-3">
					<InputWrapper label={t("task_user")} htmlFor="user_id">
						<DynamicSelect
							endpoint="/users"
							labelProperty="full_name"
							placeholder={t("edit_task_select_user")}
							defaultValue={
								formData?.user_id > 0 ? formData?.user_id : null
							}
							onChange={e =>
								handleInput({
									target: {
										name: "user_id",
										value: e ? e.value : -1,
									},
								})
							}
						/>
					</InputWrapper>
				</div>
				<div className="col-6 pr-0 pl-3">
					<InputWrapper label={t("task_object")} htmlFor="object_id">
						<DynamicSelect
							endpoint="/objects"
							labelProperty="name"
							placeholder={t("edit_task_select_object")}
							defaultValue={
								formData?.object_id > 0
									? formData?.object_id
									: null
							}
							onChange={e =>
								handleInput({
									target: {
										name: "object_id",
										value: e ? e.value : -1,
									},
								})
							}
						/>
					</InputWrapper>
				</div>
			</div>

			<div className="d-flex mb-3">
				<div className="col-6 position-static pl-0 pr-3">
					<InputWrapper
						label={t("task_date_from")}
						htmlFor="date_from"
						notInputGroup
					>
						<DatePicker
							name="date_from"
							showTimeSelect
							timeFormat="HH:mm"
							dateFormat="yyyy-MM-dd, HH:mm"
							selected={
								formData?.date_from
									? new Date(parseInt(formData.date_from))
									: null
							}
							onChange={newDate =>
								handleInput({
									target: {
										value: +newDate,
										name: "date_from",
									},
								})
							}
							wrapperClassName="w-100"
							className="input input-light"
						/>
					</InputWrapper>
				</div>
				<div className="col-6 position-static pr-0 pl-3">
					<InputWrapper
						label={t("task_date_to")}
						htmlFor="date_to"
						notInputGroup
					>
						<DatePicker
							name="date_to"
							showTimeSelect
							timeFormat="HH:mm"
							dateFormat="yyyy-MM-dd, HH:mm"
							selected={
								formData?.date_to
									? new Date(parseInt(formData.date_to))
									: null
							}
							onChange={newDate =>
								handleInput({
									target: {
										value: +newDate,
										name: "date_to",
									},
								})
							}
							wrapperClassName="w-100"
							className="input input-light"
						/>
					</InputWrapper>
				</div>
			</div>

			<InputWrapper label={t("attachments")}>
				<FilesUpload
					defaultFilesStr={formData?.image}
					setFiles={setImages}
				/>
			</InputWrapper>

			<InputWrapper label={t("files")}>
				<FilesUpload
					type="documents"
					defaultFilesStr={formData?.file}
					setFiles={setDocuments}
				/>
			</InputWrapper>

			{error && <Alert success={false}>{error}</Alert>}

			<button ref={formSubmitButton} className="d-none">
				Submit form
			</button>
		</form>
	);
};

export default TaskForm;
