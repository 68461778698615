import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import { TASKS_PAGE_PATH } from "routes";
import uploadImages from "utils/uploadImages";
import uploadFiles from "utils/uploadFiles";
import UserContext from "contexts/UserContext";
import TasksContext from "contexts/TasksContext";
import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import TaskForm from "./TaskForm";

const createDefaultFormData = () => ({
	date_from: +new Date(),
	date_to: +new Date(),
});

const CreateTaskModal = ({ show, disableModal }) => {
	const { t } = useTranslation();
	const [, setTasks] = useContext(TasksContext);
	const [user] = useContext(UserContext);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData, handleInput] = useForm(
		createDefaultFormData()
	);
	const [images, setImages] = useState([]);
	const [documents, setDocuments] = useState([]);
	const formSubmitButton = useRef();

	const location = useLocation();

	useEffect(() => {
		setFormData(createDefaultFormData());
		setImages([]);
		setDocuments([]);
	}, [show, setFormData]);

	const createTask = async e => {
		e.preventDefault();

		if (saving) return;
		setSaving(true);
		setError(null);

		let [fileNamesStr, error] = await uploadImages(images, formData.image);
		if (error) {
			setSaving(false);
			return setError(error);
		}
		let [docFileNamesStr, filesUploadError] = await uploadFiles(documents);
		if (filesUploadError) {
			setSaving(false);
			return setError(filesUploadError);
		}

		let updatedFormData = {
			...formData,
			image: fileNamesStr,
			file: docFileNamesStr,
			assigned_users: [user.userData.id],
		};
		if (formData.user_id)
			updatedFormData.assigned_users.push(formData.user_id);
		else updatedFormData.user_id = -1;

		if (!formData.object_id) formData.object_id = -1;

		api.post("/task", updatedFormData)
			.then(response => {
				if (response.data.error) setError(response.data.error);
				else {
					// ADD TASK TO LIST ONLY IF WE ARE IN TASKS PAGE
					if (location.pathname.includes(TASKS_PAGE_PATH))
						// FETCH TASK BECAUSE POST REQUEST DOESN'T RETURN ALL THE INFO NEEDED
						api.get(`/tasks`, {
							params: { task_id: parseInt(response.data.data) },
						}).then(taskResponse => {
							if (taskResponse.data.error) return;
							if (!taskResponse.data.data.length) return;
							setTasks(tasks => ({
								list: [
									taskResponse.data.data[0],
									...tasks.list,
								],
								additionalOffset: tasks.additionalOffset + 1,
							}));
						});
				}
			})
			.catch(() => NotificationManager.error(t("error_unknown")))
			.finally(() => {
				setSaving(false);
				disableModal();
			});
	};

	return (
		<Modal
			icon
			title={t("menu_create_new_task")}
			show={show}
			disableModal={disableModal}
			footerButtons={
				<Button
					onClick={() => formSubmitButton.current.click()}
					loading={saving}
					className="btn-primary"
				>
					{t("menu_create_new_task")}
				</Button>
			}
		>
			<TaskForm
				onSubmit={createTask}
				handleInput={handleInput}
				formSubmitButton={formSubmitButton}
				formData={formData}
				images={images}
				setImages={setImages}
				setDocuments={setDocuments}
				error={error}
			/>
		</Modal>
	);
};

export default CreateTaskModal;
