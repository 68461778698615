import React, { useEffect, useReducer, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Pusher from "pusher-js";
import { CHAT_PAGE_PATH, TASKS_PAGE_PATH } from "routes";
import api from "api";

import UserContext from "contexts/UserContext";
import ChatContext from "contexts/ChatContext";
import { filterSubchannels } from "pages/Chat/utils";

const defaultChatState = {
	subchannels: [],
	activeSubchannels: [],
	loaded: false,
};

const reducer = (state, action) => {
	switch (action.type) {
		case "reset": {
			return defaultChatState;
		}
		case "set_loaded":
			return { ...state, loaded: action.payload };
		case "set_seen_messages":
			return {
				...state,
				activeSubchannels: state.activeSubchannels.map(subchannel =>
					subchannel.channel_id === action.payload.channel_id
						? {
								...subchannel,
								seenMessages: action.payload.seenMessages,
						  }
						: subchannel
				),
			};
		case "set_messages":
			return {
				...state,
				activeSubchannels: state.activeSubchannels.map(subchannel =>
					subchannel.channel_id === action.payload.channel_id
						? { ...subchannel, messages: action.payload.messages }
						: subchannel
				),
			};
		case "set_messages_callback":
			return {
				...state,
				activeSubchannels: state.activeSubchannels.map(subchannel =>
					subchannel.channel_id === action.payload.channel_id
						? {
								...subchannel,
								messages: action.payload.callback(
									subchannel.messages || []
								),
						  }
						: subchannel
				),
			};
		case "add_message":
			return {
				...state,
				activeSubchannels: state.activeSubchannels.map(subchannel =>
					subchannel.channel_id === action.payload.channel_id
						? {
								...subchannel,
								messages: [
									...(subchannel.messages
										? subchannel.messages
										: []),
									action.payload.newMessageObj,
								],
						  }
						: subchannel
				),
			};
		case "set_unread_message_id":
			return {
				...state,
				activeSubchannels: state.activeSubchannels.map(subchannel =>
					subchannel.channel_id === action.payload.channel_id
						? {
								...subchannel,
								unreadMessageId: action.payload.data,
						  }
						: subchannel
				),
			};
		case "set_subchannels":
			return { ...state, subchannels: action.payload };
		case "set_subchannels_callback":
			return {
				...state,
				subchannels: action.payload.callback(state.subchannels),
			};
		case "set_active_subchannel": {
			let activeSubchannelIndex = state.activeSubchannels.findIndex(
				subchannel =>
					subchannel.channel_id ===
					action.payload.subchannel_info?.channel_id
			);
			if (action.payload.isChatPage) {
				return {
					...state,
					activeSubchannels: [action.payload.subchannel_info],
				};
			}

			if (activeSubchannelIndex === -1) {
				return {
					...state,
					activeSubchannels: [
						...state.activeSubchannels,
						action.payload.subchannel_info,
					],
				};
			}

			let newState = { ...state };
			newState.activeSubchannels[activeSubchannelIndex] = {
				...newState.activeSubchannels[activeSubchannelIndex],
				...action.payload.subchannel_info,
			};
			return newState;
		}
		case "filter_active_subchannels":
			return {
				...state,
				activeSubchannels: action.payload.callback(
					state.activeSubchannels
				),
			};
		case "mark_seen_messages": {
			let newState = { ...state };

			let tempSeenMessages = action.payload.seen;
			let unreadMessageId = null;
			if (typeof tempSeenMessages !== "object")
				tempSeenMessages = JSON.parse(tempSeenMessages);
			if (!tempSeenMessages) tempSeenMessages = {};
			if (tempSeenMessages[action.payload.user?.userData?.id])
				unreadMessageId =
					tempSeenMessages[action.payload.user?.userData?.id]?.chat;

			const lastMessageId = action.payload.lastMessage.id;
			if (tempSeenMessages[action.payload.user.userData.id]) {
				api.post("/seen_chat", {
					channel_id: action.payload.channel_id,
					latest_chat_seen: lastMessageId,
				});
			}
			tempSeenMessages[action.payload.user.userData.id] = {
				...tempSeenMessages[action.payload.user.userData.id],
				chat: lastMessageId,
			};

			newState.activeSubchannels = newState.activeSubchannels.map(
				subchannel =>
					subchannel.channel_id === action.payload.channel_id
						? {
								...subchannel,
								seenMessages: tempSeenMessages,
								unreadMessageId,
						  }
						: subchannel
			);

			return newState;
		}
		case "set_additional_offset_callback": {
			return {
				...state,
				activeSubchannels: state.activeSubchannels.map(
					activeSubchannel =>
						activeSubchannel.channel_id ===
						action.payload.channel_id
							? {
									...activeSubchannel,
									additionalOffset: action.payload.callback(
										activeSubchannel.additionalOffset || 0
									),
							  }
							: activeSubchannel
				),
			};
		}
		default:
			return state;
	}
};

const ChatWrapper = ({ children }) => {
	const [user] = useContext(UserContext);
	const [chat, dispatch] = useReducer(reducer, defaultChatState);
	const location = useLocation();
	const lastLocation = useRef(null);

	const pusher = useRef();
	const channelName = useRef();

	const isCurrentlyInChatPage = location.pathname.includes(CHAT_PAGE_PATH);

	const channel = useRef();

	const history = useHistory();

	useEffect(() => {
		if (
			lastLocation.current?.includes(TASKS_PAGE_PATH) &&
			!location.pathname.includes(TASKS_PAGE_PATH)
		) {
			dispatch({
				type: "set_subchannels_callback",
				payload: {
					callback: subchannels =>
						subchannels.filter(
							subchannel =>
								!subchannel.task_id && !subchannel.task
						),
				},
			});
			dispatch({
				type: "filter_active_subchannels",
				payload: {
					callback: subchannels =>
						subchannels.filter(
							subchannel =>
								!subchannel.task_id && !subchannel.task
						),
				},
			});
		}
		if (
			lastLocation.current?.includes(CHAT_PAGE_PATH) &&
			lastLocation.current !== CHAT_PAGE_PATH &&
			location.pathname === CHAT_PAGE_PATH
		) {
			// REMOVE ACTIVE SUBCHANNEL(S) WHEN GOING FROM INTERNAL CHAT PAGE TO MAIN CHAT PAGE
			dispatch({
				type: "filter_active_subchannels",
				payload: {
					callback: subchannels => subchannels.filter(() => false),
				},
			});
		}

		if (
			!lastLocation.current?.includes(CHAT_PAGE_PATH) &&
			location.pathname === CHAT_PAGE_PATH &&
			chat.activeSubchannels.length > 0
		) {
			history.push(
				`${CHAT_PAGE_PATH}/${chat.activeSubchannels[0].channel_id}`
			);
		}
		lastLocation.current = location.pathname;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	useEffect(() => {
		if (!user.userData?.id) return;
		dispatch({ type: "reset" });
		api.get("/sub_channels").then(response => {
			dispatch({
				type: "set_subchannels_callback",
				// payload: filterSubchannels({ subchannels: response.data.data }),
				payload: {
					callback: subchannels => [
						...subchannels,
						...filterSubchannels({
							subchannels: response.data.data,
						}),
					],
				},
			});
			dispatch({ type: "set_loaded", payload: true });
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.userData?.id]);

	useEffect(() => {
		// if (channel.current) return;
		if (user?.userData?.id) {
			channelName.current = `user_${user?.userData?.id}`;
			pusher.current = new Pusher("991fd1a286a46849aa62", {
				cluster: "eu",
				encrypted: true,
			});
			channel.current = pusher.current.subscribe(channelName.current);
		} else if (pusher.current && channelName.current) {
			pusher.current.unsubscribe(channelName.current);
		}

		return function cleanup() {
			if (pusher.current) {
				if (channelName.current)
					pusher.current.unsubscribe(channelName.current);
				pusher.current.disconnect();
				pusher.current = null;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.userData?.id]);

	useEffect(() => {
		if (!channel?.current) return;
		channel.current.bind("new_message", data => {
			if (!data) return;
			const { message } = data;
			if (
				!message.value &&
				!message.image &&
				!message.document &&
				!message.sound
			)
				return;

			const newMessageObj = {
				...message,
				created_at: +new Date(),
				updated_at: +new Date(),
			};

			const audio = new Audio(
				require("assets/sound/Messenger notification tone.mp4")?.default
			);
			try {
				audio.play();
			} catch {
				// USER PROBABLY DIDN'T INTERACTED WITH WEBSITE SO WE CAN'T PLAY AUDIO FOR HIM
			}

			let subchannel_info = {
				...newMessageObj,
			};

			let activeSubchannel = {};

			let isChannelActive = Boolean(
				chat.activeSubchannels.find(subchannel => {
					activeSubchannel = subchannel;
					return subchannel.channel_id === message.channel_id;
				})
			);

			if (
				(activeSubchannel.task && isChannelActive) ||
				!activeSubchannel.task
			) {
				dispatch({
					type: "set_subchannels_callback",
					payload: {
						callback: subchannels => {
							if (
								subchannels.find(
									subchannel =>
										subchannel.channel_id ===
										newMessageObj.channel_id
								)
							) {
								return subchannels.map(subchannel =>
									subchannel.channel_id ===
									newMessageObj.channel_id
										? { ...subchannel, ...newMessageObj }
										: subchannel
								);
							}
							return [
								...subchannels,
								{
									channel_title: newMessageObj.sender_name,
									...subchannel_info,
								},
							];
						},
					},
				});
			}

			if (!isCurrentlyInChatPage && !isChannelActive) {
				let subchannelThatIsActive = chat.subchannels.find(
					subchannel =>
						subchannel.channel_id === newMessageObj.channel_id
				);

				dispatch({
					type: "set_active_subchannel",
					payload: {
						subchannel_info: {
							channel_title: newMessageObj.sender_name,
							...(subchannelThatIsActive
								? subchannelThatIsActive
								: {}),
							additionalOffset: 0,
							...subchannel_info,
						},
					},
				});
			}

			if (isChannelActive) {
				let old_subchannel_info = chat.subchannels.find(
					subchannel =>
						subchannel.channel_id === subchannel_info.channel_id
				);
				// console.log(newMessageObj);
				if (old_subchannel_info) {
					dispatch({
						type: "set_active_subchannel",
						payload: {
							subchannel_info: {
								additionalOffset: 0,
								...old_subchannel_info,
								...subchannel_info,
							},
						},
					});
				}
				dispatch({
					type: "add_message",
					payload: {
						channel_id: newMessageObj.channel_id,
						newMessageObj,
					},
				});
				dispatch({
					action: "mark_seen_messages",
					payload: {
						channel_id: newMessageObj.channel_id,
						seen: message.seen,
						lastMessage: message,
						user,
					},
				});
			}
		});
		return function cleanup() {
			channel.current.unbind("new_message");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channel.current, chat.activeSubchannels, chat.subchannels]);

	return (
		<ChatContext.Provider value={[chat, dispatch]}>
			{children}
		</ChatContext.Provider>
	);
};

export default ChatWrapper;
