import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import api from "api";

// import LoadingWrapper from "components/core/LoadingWrapper";
import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
// import Select from "components/core/Select";
import Alert from "components/core/Alert";
import PlusCircle from "components/PlusCircle";
import ConfirmModal from "components/core/ConfirmModal";
import CreateToolModal from "./CreateToolModal";
import EditToolModal from "./EditToolModal";
import Tool from "./Tool";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";
import SubHeader from "components/SubHeader";
import Spinner from "components/core/Spinner";
import useBottomScrollListener from "hooks/useBottomScrollListener";
import UserContext from "contexts/UserContext";

const limit = 21;

const Tools = () => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [tools, setTools] = useState([]);
	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [toolsType, setToolsType] = useState("ALL");
	const [removeToolModal, setRemoveToolModal] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [createToolModal, setCreateToolModal] = useState(false);
	const [editToolModal, setEditToolModal] = useState(false);

	const [additionalOffset, setAdditionalOffset] = useState(0);
	const [page, setPage] = useState(0);
	const [refetch, setRefetch] = useState(0);
	const [initiallyFetched, setInitiallyFetched] = useState(false);

	const [scrollRef, , setNextPage] = useBottomScrollListener(() =>
		setPage(page => page + 1)
	);

	useEffect(() => {
		setPage(0);
		setAdditionalOffset(0);
		setRefetch(refetch => refetch + 1);
	}, [searchText, toolsType, setPage, setTools]);

	useEffect(() => {
		// if (fetching) return; // by not fetching results when it's already happening, some search inputs will be skipped when input is faster than request, that's why this line should be commented out
		setFetching(true);

		api.get("/materials", {
			params: {
				offset: page * limit + additionalOffset,
				limit: limit + 1,
				query: searchText,
				filter_by: toolsType,
			},
		})
			.then(response => {
				if (response.data.error) setError(response.data.error);
				else {
					if (response.data.data.length > limit) {
						setTools(tools => [
							...(page === 0 ? [] : tools),
							...response.data.data.slice(0, -1),
						]);
						setNextPage(true);
					} else {
						setTools(tools => [
							...(page === 0 ? [] : tools),
							...response.data.data,
						]);
						if (response.data.data.length < limit)
							setNextPage(false);
						else setNextPage(true);
					}
				}
			})
			.catch(() => setError(t("error_unknown")))
			.finally(() => {
				setFetching(false);
				if (!initiallyFetched) setInitiallyFetched(true);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, refetch, page]);

	const removeTool = () => {
		if (removing) return;
		setRemoving(true);

		api.put("/material", {
			material_id: removeToolModal,
			status: "REMOVED",
		})
			.then(response => {
				if (response.data.error)
					NotificationManager.error(response.data.error);
				else {
					setTools(tools =>
						tools.map(tool =>
							tool.id === removeToolModal
								? { ...tool, status: "REMOVED" }
								: tool
						)
					);
				}
			})
			.catch(() => NotificationManager.error(t("error_unknown")))
			.finally(() => setRemoving(false));
	};

	const toolTypeOptions = [
		{
			value: "ALL",
			label: t("tools_all_tools"),
		},
		{
			value: "AVAILABLE",
			label: t("tools_available"),
		},
		{
			value: "REMOVED",
			label: t("tools_removed"),
		},
		{
			value: "TAKEN",
			label: t("tools_taken"),
		},
		{
			value: "IN_REPAIR",
			label: t("tools_in_repair"),
		},
		{
			value: "BROKEN",
			label: t("tools_broken"),
		},
	];

	const toolsList = (
		<div className="tools-list">
			{tools.map(tool => (
				<Tool
					key={tool.id}
					data={tool}
					setEditToolModal={setEditToolModal}
					setRemoveToolModal={setRemoveToolModal}
					t={t}
				/>
			))}
		</div>
	);

	return (
		<div className="tools-wrapper">
			<SubHeader className="container-fluid d-flex justify-content-between pt-0">
				<div className="d-flex align-items-center">
					<h2>{t("menu_tools_list")}</h2>
				</div>
				<div className="d-flex align-items-center">
					<Select
						className="dynamic-select tool-type-select"
						classNamePrefix="dynamic-select"
						value={toolTypeOptions.find(
							option => option.value === toolsType
						)}
						name="tools_type"
						onChange={v => setToolsType(v.value)}
						options={toolTypeOptions}
						isSearchable={false}
					/>
					{/* <Select
						name="tools_type"
						onChange={e => setToolsType(e.target.value)}
						selected={toolsType}
						initialChange={false}
						className="task-type-select mr-3"
						options={toolTypeOptions}
					/> */}

					<InputWrapper>
						<Input
							onChange={e => setSearchText(e.target.value)}
							placeholder={`${t("app_search")}...`}
							icon={<i className="fas fa-search"></i>}
							dark
						/>
					</InputWrapper>
					{user?.userData?.role !== "WORKER" && (
						<PlusCircle
							onClick={() => setCreateToolModal(true)}
							className="ml-2"
						/>
					)}
				</div>
			</SubHeader>
			<FullScreenHeightWrapper ref={scrollRef} className="bg-secondary">
				<div className="container-fluid">
					{/* <LoadingWrapper
						fetched={initiallyFetched}
						size={120}
						className="text-center"
					>
						<> */}
					{error ? (
						<Alert success={false}>{error}</Alert>
					) : (
						<>
							{!tools.length && !fetching && (
								<h4 className="text-center py-3">
									{t("tools_list_empty")}
								</h4>
							)}

							{toolsList}
							{fetching && <Spinner size={80} color="#343a40" />}
						</>
					)}
					{/* </>
					</LoadingWrapper> */}
				</div>
			</FullScreenHeightWrapper>

			<ConfirmModal
				show={Boolean(removeToolModal)}
				setModal={setRemoveToolModal}
				title={t("tools_want_to_remove")}
				buttons={[
					{
						name: t("tools_want_to_remove_yes"),
						className: "btn-primary",
						callback: removeTool,
					},
					{
						name: t("tools_want_to_remove_no"),
						className: "btn-danger",
					},
				]}
			/>
			{/* <Backdrop show={removing}>
				<Spinner size={120} centerVertically />
			</Backdrop> */}

			<CreateToolModal
				show={createToolModal}
				disableModal={() => setCreateToolModal(false)}
				setTools={setTools}
				setAdditionalOffset={setAdditionalOffset}
			/>

			<EditToolModal
				show={editToolModal}
				disableModal={() => setEditToolModal(null)}
				setTools={setTools}
				tool={editToolModal}
				setTool={setEditToolModal}
			/>
		</div>
	);
};

export default Tools;
