import React from "react";
import styled from "styled-components";
import classNames from "classnames";

const StyledIconWrapper = styled.div`
	position: relative;
	display: inline-block;
	padding: 0.4375rem;
	height: ${props => props.size}rem;
	width: ${props => props.size}rem;
	font-size: 0.95rem;
	background-color: #ecedf2;
	color: #9292a7;
	border-radius: 50%;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const IconWrapper = ({ type, className, size = 2, children }) => {
	return (
		<StyledIconWrapper
			size={size}
			className={classNames([
				{ [`bg-${type} text-white`]: type },
				className,
			])}
		>
			{children}
		</StyledIconWrapper>
	);
};

export default IconWrapper;
