import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "style/main.scss";
import ErrorBoundary from "./components/core/ErrorBoundary";
import * as Sentry from "@sentry/react";
import "./i18n";

Sentry.init({
	dsn:
		"https://454409b2d9b245b6b2691502befec581@o406086.ingest.sentry.io/5387381",
});

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
