import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import uploadImages from "utils/uploadImages";
import uploadFiles from "utils/uploadFiles";
import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import ToolForm from "./ToolForm";

const CreateToolModal = ({
	show,
	disableModal,
	setTools,
	setAdditionalOffset,
}) => {
	const { t } = useTranslation();
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData, handleInput] = useForm({});
	const [images, setImages] = useState([]);
	const [documents, setDocuments] = useState([]);
	const formSubmitButton = useRef();

	useEffect(() => {
		setFormData({});
	}, [show, setFormData]);

	const createTool = async e => {
		e.preventDefault();

		if (saving) return;
		setSaving(true);
		setError(null);

		let [filenamesStr, error] = await uploadImages(images, formData.image);
		if (error) {
			setSaving(false);
			return setError(error);
		}
		let [docFilenamesStr, filesUploadError] = await uploadFiles(documents);
		if (filesUploadError) {
			setSaving(false);
			return setError(filesUploadError);
		}

		const status = !formData.userId ? "AVAILABLE" : "TAKEN";
		const newTool = {
			...formData,
			status,
			image: filenamesStr,
			file: docFilenamesStr,
		};

		if (!newTool.user_id) newTool.user_id = 0;
		if (!newTool.object_id) newTool.object_id = -1;

		api.post("/material", newTool)
			.then(response => {
				setSaving(false);
				if (response.data.error) setError(response.data.error);
				else {
					setTools(tools => [
						{ id: response.data.data, ...newTool },
						...tools,
					]);
					setAdditionalOffset(offset => offset + 1);
					disableModal();
				}
			})
			.catch(() => {
				NotificationManager.error(t("error_unknown"));
				setSaving(false);
			});
	};

	return (
		<Modal
			icon
			title={t("edit_tool_navigation_title_create")}
			show={show}
			disableModal={disableModal}
			footerButtons={
				<Button
					onClick={() => formSubmitButton.current.click()}
					loading={saving}
					className="btn-primary"
				>
					{t("edit_tool_create_button_text")}
				</Button>
			}
		>
			<ToolForm
				onSubmit={createTool}
				handleInput={handleInput}
				formSubmitButton={formSubmitButton}
				formData={formData}
				setImages={setImages}
				setDocuments={setDocuments}
				error={error}
			/>
		</Modal>
	);
};

export default CreateToolModal;
