import axios from "axios";

const baseURL = "https://api.taskspad.com"; // PRODUCTION
// const baseURL = "https://taskspadbackendapi.com";

const axiosInstance = axios.create({
	baseURL,
});

const setAuthToken = token => {
	axiosInstance.defaults.headers.common = {
		auth: token,
	};
};

if (localStorage.getItem("token")) setAuthToken(localStorage.getItem("token"));

export { setAuthToken, baseURL };
export default axiosInstance;
