import React, { useContext, useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import classNames from "classnames";
import IsMobileContext from "contexts/IsMobileContext";
import UserContext from "contexts/UserContext";
import LazyImageContext from "contexts/LazyImgContext";
import routes, { CHAT_PAGE_PATH, TASKS_PAGE_PATH } from "routes";
import Header from "components/Header";
import RouteWrapper from "components/core/RouteWrapper";
import NotFoundPage from "components/core/NotFoundPage";
import ContactsList from "components/ContactsList";
import StickedChatList from "components/StickedChatList";

const AppContent = () => {
	const [user] = useContext(UserContext);
	const phone = useContext(IsMobileContext);
	const [, dispatchLazyImages] = useContext(LazyImageContext);
	const location = useLocation();

	// Reset lazy images to prevent unloaded images to stay there and block loading for new lazy images
	useEffect(() => {
		dispatchLazyImages({ type: "RESET_LOADING_LIST" });
	}, [location, dispatchLazyImages]);

	const isCurrentInChatPage = location.pathname.includes(CHAT_PAGE_PATH);

	return (
		<div className="d-flex">
			<div className={user.authorized && !phone ? "col-10" : "col-12"}>
				{user.authorized && <Header />}

				<div
					className={classNames(
						"container-fluid",
						{ "p-0": user.authorized },
						{ "vw-100": isCurrentInChatPage }
					)}
				>
					<Switch>
						{routes.map(route => (
							<RouteWrapper
								key={route.path || route.link}
								path={route.path || route.link}
								exact={route.exact}
								route={route}
								user={user}
							/>
						))}
						<Redirect from="/" to={TASKS_PAGE_PATH} exact />
						<Route component={NotFoundPage} />
					</Switch>
					{!isCurrentInChatPage && !phone && user.authorized && (
						<StickedChatList />
					)}
				</div>
			</div>
			{user.authorized && !isCurrentInChatPage && !phone && (
				<div className="col-2">
					<ContactsList />
				</div>
			)}
		</div>
	);
};

export default AppContent;
