async function getAudioMetaDataCallback(url, callback) {
	const audio = document.createElement("audio");
	audio.src = url;

	audio.onloadedmetadata = () => {
		callback(audio);
		// audio.parentNode.removeChild(audio);
	};
}

export default getAudioMetaDataCallback;
