import React from "react";
import styled from "styled-components";

const BackdropStyled = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(10, 10, 10, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.4s;

	visibility: ${props => (props.show ? "visible" : "hidden")};
	opacity: ${props => (props.show ? "1" : "0")};
`;

const Backdrop = ({ show, children, className, zIndex = 10003 }) => {
	return (
		<BackdropStyled style={{ zIndex }} show={show} className={className}>
			{children}
		</BackdropStyled>
	);
};

export default Backdrop;
