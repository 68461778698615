import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import ConfirmModal from "components/core/ConfirmModal";

const DeleteObjectModal = ({ objectID, setModal, setObjects }) => {
	const { t } = useTranslation();

	const deleteObject = () => {
		api.put("/object", { is_enabled: false, object_id: objectID })
			.then(response => {
				if (response.data.error)
					NotificationManager.error(response.data.error);
				else
					setObjects(objects =>
						objects.filter(object => object.id !== objectID)
					);
			})
			.catch(() => NotificationManager.error(t("error_unknown")));
	};

	return (
		<ConfirmModal
			show={Boolean(objectID)}
			title={t("delete_object")}
			setModal={setModal}
			buttons={[
				{
					name: t("no"),
				},
				{
					name: t("yes"),
					callback: deleteObject,
				},
			]}
		/>
	);
};

export default DeleteObjectModal;
