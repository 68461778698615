import Tasks from "pages/Tasks/";
import TaskHistory from "pages/Tasks/TaskHistory";
import Tools from "pages/Tools/";
import ToolHistory from "pages/Tools/History";
import Settings from "pages/Settings/";
import Users from "pages/Users/";
import Objects from "pages/Objects/";
import Chat from "pages/Chat/";
import Login from "pages/Login/";

const CHAT_PAGE_PATH = "/chat";
const TASKS_PAGE_PATH = "/tasks";

const routes = [
	{
		name: "menu_tools_list",
		link: "/tools",
		component: Tools,
		exact: true,
		authorizedOnly: true,
		// icon: "svg/navigationIcons/service.svg",
		fontawesomeIcon: "fas fa-tools",
	},
	{
		name: "tool_history_navigation_title",
		path: "/tools/:id/history",
		component: ToolHistory,
		exact: true,
		authorizedOnly: true,
	},
	{
		name: "conversations_title",
		link: CHAT_PAGE_PATH,
		path: CHAT_PAGE_PATH + "/:id?/",
		component: Chat,
		exact: true,
		authorizedOnly: true,
		fontawesomeIcon: "fas fa-comment-dots",
	},
	{
		name: "menu_settings",
		link: "/settings",
		component: Settings,
		exact: true,
		authorizedOnly: true,
		fontawesomeIcon: "fas fa-cog",
	},
	{
		name: "Users",
		link: "/settings/users",
		component: Users,
		exact: true,
		authorizedOnly: true,
		roles: ["ADMIN", "SUPER"],
	},
	{
		name: "Objects",
		link: "/settings/objects",
		component: Objects,
		exact: true,
		authorizedOnly: true,
		roles: ["ADMIN", "SUPER"],
	},
	{
		name: "Login",
		link: "/login",
		component: Login,
		exact: true,
		unauthorizedOnly: true,
	},
	{
		name: "menu_task_list",
		link: TASKS_PAGE_PATH,
		path: TASKS_PAGE_PATH + "/:id?/",
		component: Tasks,
		exact: true,
		authorizedOnly: true,
		// icon: "svg/navigationIcons/home.svg",
		fontawesomeIcon: "fas fa-home",
	},
	{
		name: "task_history_navigation_title",
		path: TASKS_PAGE_PATH + "/:id/history",
		component: TaskHistory,
		exact: true,
		authorizedOnly: true,
	},
];

const navigation = [routes[7], routes[0], routes[2], routes[3]];

export default routes;
export { CHAT_PAGE_PATH, TASKS_PAGE_PATH, navigation };
