import React from "react";
import { Link } from "react-router-dom";

const OptionButton = ({
	to,
	title,
	iconClassName,
	CustomRightSide,
	arrowRight = true,
	...props
}) => {
	const content = (
		<>
			<div className="d-flex align-items-center">
				<div className="icon-wrapper">
					<i className={iconClassName}></i>
				</div>
				<span className="option--name">{title}</span>
			</div>
			<div className="d-flex align-items-center">
				{CustomRightSide && <CustomRightSide />}
				{arrowRight && <i className="fas fa-arrow-right"></i>}
			</div>
		</>
	);

	if (to)
		return (
			<Link to={to} className="option" {...props}>
				{content}
			</Link>
		);
	return (
		<div className="option" {...props}>
			{content}
		</div>
	);
};

export default OptionButton;
