import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

import IsMobileContext from "contexts/IsMobileContext";
import UserContext from "contexts/UserContext";
import { navigation as routes } from "routes";

const Navigation = () => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const phone = useContext(IsMobileContext);
	const [menu, setMenu] = useState(false);

	return (
		<>
			{phone && !menu && (
				<button
					className="empty-button menu-button"
					onClick={() => setMenu(true)}
				>
					<i className="fas fa-bars"></i>
				</button>
			)}
			<div
				id="navigation"
				className={classNames(
					{ "active": menu },
					"d-flex align-items-center h-100"
				)}
			>
				{phone && (
					<button
						className="empty-button mt-2"
						onClick={() => setMenu(false)}
					>
						<svg height="20px" width="20px" viewBox="-4 -4 24 24">
							<line
								stroke="#c5c6ca"
								strokeLinecap="round"
								strokeWidth="2"
								x1="2"
								x2="14"
								y1="2"
								y2="14"
							></line>
							<line
								stroke="#c5c6ca"
								strokeLinecap="round"
								strokeWidth="2"
								x1="2"
								x2="14"
								y1="14"
								y2="2"
							></line>
						</svg>
					</button>
				)}
				{routes.map(route => {
					if (
						(route.authorizedOnly && !user.authorized) ||
						(route.unauthorizedOnly && user.authorized)
					)
						return null;
					return (
						<NavLink
							onClick={() => setMenu(false)}
							exact={route.link === "/"}
							key={route.link}
							to={route.link}
						>
							{route.icon && <ReactSVG src={route.icon} />}
							{route.fontawesomeIcon && (
								<i className={route.fontawesomeIcon}></i>
							)}
							{t(route.name)}
						</NavLink>
					);
				})}
			</div>
		</>
	);
};

export default Navigation;
