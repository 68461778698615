import React, { useContext } from "react";

import ChatContext from "contexts/ChatContext";
import StickedChat from "./StickedChat";

const StickedChatList = () => {
	const [chat] = useContext(ChatContext);

	if (!chat.activeSubchannels.length) return null;

	const miniChatList = chat.activeSubchannels.map(subchannel =>
		!subchannel.task_id ? (
			<StickedChat key={subchannel.channel_id} subchannel={subchannel} />
		) : null
	);

	return <div className="mini-chat-wrapper">{miniChatList}</div>;
};

export default StickedChatList;
