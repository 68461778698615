import React from "react";
import classNames from "classnames";
import Linkify from "react-linkify";

import ZoomableImage from "components/ZoomableImage";
import AudioPlayer from "components/AudioPlayer";

const MessageBubble = ({ data, presentUserId, setForwardModal }) => {
	if (!data.value && !data.image && !data.document && !data.sound)
		return null;

	let title = new Date(+data.updated_at).toLocaleTimeString("lt-LT", {
		hour: "2-digit",
		minute: "2-digit",
	});

	let images = null;
	let files = null;
	let soundRecords = null;

	if (data.image?.length) {
		if (!data.image.includes(",")) {
			images = (
				<ZoomableImage
					filename={data.image}
					title={title}
					channelId={data.channel_id}
				/>
			);
		} else {
			images = (
				<div className="images-list" title={title}>
					{data.image.split(",").map(imageName => (
						<ZoomableImage
							key={imageName}
							filename={imageName}
							channelId={data.channel_id}
						/>
					))}
				</div>
			);
		}
	}

	if (data.document?.length) {
		if (!data.document.includes(",")) {
			files = (
				<ZoomableImage
					filename={data.document}
					title={title}
					channelId={data.channel_id}
					document={true}
				/>
			);
		} else {
			files = (
				<div title={title}>
					{data.document.split(",").map(imageName => (
						<ZoomableImage
							key={imageName}
							filename={imageName}
							channelId={data.channel_id}
							document={true}
						/>
					))}
				</div>
			);
		}
	}

	if (data.sound?.length) {
		if (!data.sound.includes(",")) {
			soundRecords = (
				<AudioPlayer
					url={data.sound}
					duration={parseInt(data.value)}
					title={title}
				/>
			);
		}
	}

	const isTextMessage = data.value && !data.sound;

	const bubble = isTextMessage ? (
		<div className="message-bubble" title={title}>
			<Linkify>{data.value}</Linkify>
		</div>
	) : (
		images || files || soundRecords
	);

	// const user = <ObjectIcon title={data.sender_name} />;

	const isCurrentUser = data.sender_id === presentUserId;

	const forwardButton = isTextMessage ? (
		<button
			onClick={() => setForwardModal(data.value)}
			className="forward-message-button"
		>
			<img
				src={require("assets/img/ic_forward_message.png")?.default}
				alt="Forward message"
			/>
		</button>
	) : null;

	return (
		<div
			className={classNames("message-bubble-row", {
				"user-message": isCurrentUser,
			})}
		>
			{isCurrentUser ? (
				<>
					{forwardButton}
					{bubble}
				</>
			) : (
				<>
					{/* {user} */}
					{bubble}
					{forwardButton}
				</>
			)}
		</div>
	);
};

export default MessageBubble;
