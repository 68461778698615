import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import UserContext from "contexts/UserContext";
import ChatContext from "contexts/ChatContext";
import { filterSubchannels } from "pages/Chat/utils";
import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import ObjectForm from "./ObjectForm";

const CreateObjectModal = ({
	show,
	disableModal,
	// setObjects,
	setRefetchObjects,
}) => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [, chatDispatch] = useContext(ChatContext);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData, handleInput] = useForm({});
	const formSubmitButton = useRef();

	useEffect(() => {
		setFormData({});
	}, [show, setFormData]);

	const createObject = e => {
		e.preventDefault();

		if (saving) return;
		setSaving(true);
		setError(null);

		const data = { ...formData };

		if (!data.assigned_users?.length)
			data.assigned_users = [user.userData.id];

		api.post("/object", {
			...data,
		})
			.then(response => {
				setSaving(false);
				if (response.data.error) setError(response.data.error);
				else {
					setRefetchObjects(refetch => refetch + 1);

					// ADD CREATED OBJECT CHAT TO PAGE
					api.get("/sub_channels").then(subchannelsResponse => {
						chatDispatch({
							type: "set_subchannels_callback",
							payload: {
								callback: subchannels => [
									...subchannels,
									...filterSubchannels({
										subchannels: subchannelsResponse.data.data.filter(
											subchannel =>
												subchannel.channel_id ===
												response.data.channel_id
										),
									}),
								],
							},
						});
					});

					// setObjects(objects => [
					// 	...objects,
					// 	{ id: response.data.data, ...data },
					// ]);
					disableModal();
				}
			})
			.catch(() => {
				NotificationManager.error(t("error_unknown"));
				setSaving(false);
			});
	};

	return (
		<Modal
			icon
			title={t("edit_object_navigation_title_create")}
			show={show}
			disableModal={disableModal}
			footerButtons={
				<Button
					onClick={() => formSubmitButton.current.click()}
					loading={saving}
					className="btn-primary"
				>
					{t("edit_object_create_button_text")}
				</Button>
			}
		>
			<ObjectForm
				onSubmit={createObject}
				handleInput={handleInput}
				formSubmitButton={formSubmitButton}
				formData={formData}
				error={error}
			/>
		</Modal>
	);
};

export default CreateObjectModal;
