import React from "react";

const Button = ({ onClick, children, loading, className, ...restProps }) => {
	if (loading)
		return (
			<button
				className={`btn ${className || "btn-primary"}`}
				disabled
				{...restProps}
			>
				{" "}
				<span
					className="spinner-border spinner-border-sm"
					role="status"
					aria-hidden="true"
				></span>{" "}
				Loading...
			</button>
		);

	return (
		<button
			className={`btn ${className || "btn-primary"}`}
			onClick={onClick}
			{...restProps}
		>
			{children}
		</button>
	);
};

export default Button;
