import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import UserContext from "contexts/UserContext";
import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import ObjectForm from "./ObjectForm";

const EditObjectModal = ({ show, disableModal, setObjects, object }) => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData, handleInput] = useForm(object);
	const formSubmitButton = useRef();

	useEffect(() => {
		setFormData(object);
	}, [show, object, setFormData]);

	const updateObject = e => {
		e.preventDefault();

		if (saving) return;
		setSaving(true);
		setError(null);

		const data = { ...formData };

		if (!data.assigned_users?.length)
			data.assigned_users = [user.userData.id];

		api.put("/object", {
			...data,
			object_id: data.id,
		})
			.then(response => {
				setSaving(false);
				if (response.data.error) setError(response.data.error);
				else {
					setObjects(objects => {
						const objectsCopy = [...objects];
						const foundObjectIndex = objectsCopy.findIndex(
							object => object.id === data.id
						);
						if (
							foundObjectIndex === null ||
							foundObjectIndex === undefined
						)
							return objectsCopy;
						objectsCopy[foundObjectIndex] = data;
						return objectsCopy;
					});
					disableModal();
				}
			})
			.catch(() => {
				NotificationManager.error(t("error_unknown"));
				setSaving(false);
			});
	};

	return (
		<Modal
			icon
			title={t("edit_object_navigation_title")}
			show={Boolean(show)}
			disableModal={disableModal}
			footerButtons={
				<>
					<Button
						onClick={() => formSubmitButton.current.click()}
						loading={saving}
						className="btn-primary"
					>
						{t("edit_object_update_button_text")}
					</Button>
				</>
			}
		>
			<ObjectForm
				onSubmit={updateObject}
				handleInput={handleInput}
				formSubmitButton={formSubmitButton}
				formData={formData}
				error={error}
			/>
		</Modal>
	);
};

export default EditObjectModal;
