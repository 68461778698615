const STATUSES = {
	Accepted: "tasks_cell_in_progress",
	Declined: "tasks_cell_declined",
	Finished: "tasks_cell_finished",
	Created: "tasks_cell_need_accept",
};

function getTaskStatusName(task) {
	if (task.finish_date) return STATUSES.Finished;
	else if (task.accept_date) return STATUSES.Accepted;
	else if (task.declined_date) return STATUSES.Declined;
	else return STATUSES.Created;
}

export { STATUSES, getTaskStatusName };
