import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import ConfirmModal from "components/core/ConfirmModal";

const DeleteUserModal = ({ userID, setModal, setUsers }) => {
	const { t } = useTranslation();

	const deleteUser = () => {
		api.put("/user", { is_enabled: false, user_id: userID })
			.then(response => {
				if (response.data.error)
					NotificationManager.error(response.data.error);
				else
					setUsers(objects =>
						objects.filter(user => user.id !== userID)
					);
			})
			.catch(() => NotificationManager.error(t("error_unknown")));
	};

	return (
		<ConfirmModal
			show={Boolean(userID)}
			title={t("delete_user")}
			setModal={setModal}
			buttons={[
				{
					name: t("no"),
				},
				{
					name: t("yes"),
					callback: deleteUser,
				},
			]}
		/>
	);
};

export default DeleteUserModal;
