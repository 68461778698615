import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import api from "api";

import { CHAT_PAGE_PATH } from "routes";
import IsMobileContext from "contexts/IsMobileContext";
import UserContext from "contexts/UserContext";
import ChatContext from "contexts/ChatContext";
import LoadingWrapper from "components/core/LoadingWrapper";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";
import Modal from "components/core/Modal";
import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
import Switch from "components/Switch";
import Alert from "components/core/Alert";
import ObjectIcon from "components/User";
import MeasurableDiv from "components/MeasurableDiv";
import DynamicSelect from "components/DynamicSelect";
import PlusCircle from "components/PlusCircle";
import InternalChat from "./InternalChat";
import CONSTS from "consts";
import { getSubchannelTitle, filterSubchannels } from "./utils";

const Chat = ({ match }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [chat, chatDispatch] = useContext(ChatContext);
	const [user] = useContext(UserContext);
	const phone = useContext(IsMobileContext);
	const [searchText, setSearchText] = useState("");
	const [chatType, setChatType] = useState(CONSTS.USERS);
	const [filteredSubchannels, setFilteredSubchannels] = useState([]);
	const [newChatModal, setNewChatModal] = useState(false);
	const [newChatUser, setNewChatUser] = useState(null);
	const [createChatError, setCreateChatError] = useState("");

	useEffect(() => {
		if (!user?.userData?.id) return;
		setFilteredSubchannels(
			filterSubchannels({
				subchannels: chat.subchannels,
				searchText,
				chatType,
				user,
			})
		);
	}, [searchText, chatType, chat.subchannels, user]);

	useEffect(() => {
		if (newChatModal) {
			setNewChatUser(null);
			setCreateChatError("");
		}
	}, [newChatModal]);

	useEffect(() => {
		if (!match.params.id) return;
		if (!chat.subchannels.length) return;
		const id = parseInt(match.params.id);
		if (!id) return;
		if (
			chat.activeSubchannels.length &&
			chat.activeSubchannels[0].channel_id === id
		)
			return;

		const foundSubchannel = chat.subchannels.find(
			subchannel => subchannel.channel_id === id
		);
		if (!foundSubchannel) return history.push(CHAT_PAGE_PATH);

		chatDispatch({
			type: "set_active_subchannel",
			payload: {
				isChatPage: true,
				subchannel_info: foundSubchannel,
			},
		});
	}, [
		match.params.id,
		chat.activeSubchannels,
		chat.subchannels,
		chatDispatch,
	]);

	const createChat = async () => {
		let channelId = null;
		const assigners = [
			{ user_id: user.userData.id },
			{ user_id: newChatUser.id },
		];
		await api
			.post("/chat", {
				assigned_users: [assigners[0].user_id, assigners[1].user_id],
			})
			.then(response => {
				let subchannelInfo = {
					...response.data.data,
					channel_title: newChatUser.full_name,
					updated_at: +new Date(),
					assigners,
					channel_object_id: null,
				};
				chatDispatch({
					type: "set_subchannels_callback",
					payload: {
						callback: subchannels => [
							...subchannels,
							subchannelInfo,
						],
					},
				});
				chatDispatch({
					type: "set_active_subchannel",
					payload: {
						isChatPage: true,
						subchannel_info: subchannelInfo,
					},
				});
				channelId = response.data.data.channel_id;
				history.push(`${CHAT_PAGE_PATH}/${channelId}`);
			});
		// .catch(err => console.log(err));
		setNewChatUser(null);
		return channelId;
	};

	return (
		<LoadingWrapper fetched={chat?.loaded} size={60}>
			<div className="chat-wrapper d-flex">
				<div
					className={classNames(
						"users-list-col",
						{ "col-28-percent": !phone },
						{ "col-12": phone },
						{ "d-none": phone && chat?.activeSubchannels?.length }
					)}
				>
					<MeasurableDiv name="usersFilter">
						<div className="px-3">
							<InputWrapper>
								<Input
									onChange={e =>
										setSearchText(e.target.value)
									}
									placeholder={`${t("app_search")}...`}
									icon={<i className="fas fa-search"></i>}
									dark
								/>
							</InputWrapper>

							<div className="d-flex my-3 text-center">
								<Switch
									options={[
										{
											value: CONSTS.USERS,
											label: t("conversations_users"),
										},
										{
											value: CONSTS.OBJECTS,
											label: t("conversations_objects"),
										},
									]}
									onChange={newValue => setChatType(newValue)}
									style={{ maxWidth: "240px" }}
								/>
								{chatType === CONSTS.USERS && (
									<div className="text-center">
										<PlusCircle
											onClick={() =>
												setNewChatModal(true)
											}
										/>
									</div>
								)}
							</div>
						</div>

						<Modal
							show={newChatModal}
							disableModal={() => setNewChatModal(false)}
							title={t("conversations_title")}
							footerButtons={
								<button
									onClick={() => {
										if (!newChatUser?.full_name)
											return setCreateChatError(
												t(
													"select_user_navigation_title"
												)
											);
										createChat();
										setCreateChatError("");
										setNewChatModal(false);
									}}
									className="btn btn-primary"
								>
									{t("objects_confirm_users")}
								</button>
							}
						>
							<div>
								<InputWrapper
									label={t("select_user_navigation_title")}
									htmlFor="user_id"
								>
									<DynamicSelect
										endpoint="/users"
										placeholder={t(
											"select_user_navigation_title"
										)}
										labelProperty="full_name"
										onChange={e => {
											if (!e?.data) return;
											setNewChatUser(e.data);
										}}
										staticFilter={users =>
											users.map(filterUser =>
												chat.subchannels.find(
													subchannel =>
														subchannel.assigners.find(
															assigner =>
																assigner.user_id ===
																filterUser.data
																	.id
														) &&
														subchannel.assigners.find(
															assigner =>
																assigner.user_id ===
																user.userData.id
														) &&
														subchannel.assigners
															.length === 2
												)
													? {
															...filterUser,
															isDisabled: true,
													  }
													: filterUser
											)
										}
									/>
								</InputWrapper>
								{Boolean(createChatError) && (
									<div className="mt-3">
										<Alert success={false}>
											{createChatError}
										</Alert>
									</div>
								)}
							</div>
						</Modal>

						<div className="divider divider--horizontal mt-2"></div>
					</MeasurableDiv>

					<FullScreenHeightWrapper elements={["usersFilter"]}>
						<>
							<div className="users-list">
								{!filteredSubchannels.length ? (
									<p className="text-center mt-3 h5">
										{t("empty_conversations")}
									</p>
								) : (
									filteredSubchannels.map(subchannel => (
										<Link
											key={subchannel.channel_id}
											to={`${CHAT_PAGE_PATH}/${subchannel.channel_id}`}
											// onClick={() => {
											// 	if (
											// 		chat.activeSubchannels.length &&
											// 		chat.activeSubchannels[0]
											// 			.channel_id ===
											// 			subchannel.channel_id
											// 	)
											// 		return;
											// 	chatDispatch({
											// 		type: "set_active_subchannel",
											// 		payload: {
											// 			isChatPage: true,
											// 			subchannel_info: subchannel,
											// 		},
											// 	});
											// }}
											className={classNames(
												"user-row d-block",
												{
													"active":
														chat.activeSubchannels
															.length &&
														chat
															.activeSubchannels[0]
															.channel_id ===
															subchannel.channel_id,
												}
											)}
										>
											<ObjectIcon
												size={2}
												object={
													chatType === CONSTS.OBJECTS
												}
												iconWrapperClassName="user-icon-wrapper ml-2 mr-3"
											>
												<div className="d-flex justify-content-between overflow-hidden w-100">
													<div className="d-flex flex-column subchannel-info">
														<h3>
															{getSubchannelTitle(
																subchannel,
																user
															)}
														</h3>
														<span className="last-message">
															{subchannel.image
																? t(
																		"conversation_sent_image"
																  )
																: subchannel.document
																? t(
																		"conversation_sent_document"
																  )
																: subchannel.sound
																? t(
																		"conversation_sent_sound"
																  )
																: subchannel.value}
														</span>
													</div>
													<div>
														<span className="last-message-date">
															{new Date(
																parseInt(
																	subchannel.updated_at
																)
															).toLocaleString(
																"lt-LT",
																{
																	dateStyle:
																		"medium",
																	timeStyle:
																		"short",
																}
															)}
														</span>
													</div>
												</div>
											</ObjectIcon>
										</Link>
									))
								)}
							</div>
						</>
					</FullScreenHeightWrapper>
				</div>
				{((chat?.activeSubchannels?.length && phone) || !phone) && (
					<div
						className={classNames(
							{ "col-72-percent": !phone },
							{ "col-12": phone },
							"position-relative"
						)}
					>
						{Boolean(chat.activeSubchannels.length) ? (
							<InternalChat createChat={createChat} />
						) : (
							<FullScreenHeightWrapper className="d-flex justify-content-center align-items-center bg-secondary text-center">
								<h3>{t("chat_select")}</h3>
							</FullScreenHeightWrapper>
						)}
					</div>
				)}
			</div>
		</LoadingWrapper>
	);
};

export default Chat;
