import React from "react";
import classNames from "classnames";

import { useTranslation } from "react-i18next";

const SeenMessageText = ({ seenMessages, message, presentUserFullName }) => {
	const { t } = useTranslation();

	if ((!message.value?.length && !message.image?.length) || !seenMessages)
		return null;

	const usersThatSawMessage = Object.entries(seenMessages)
		.filter(
			seenMsg =>
				seenMsg[1].chat === message.id &&
				seenMsg[1].name !== presentUserFullName
		)
		.map(seenMsg => seenMsg[1].name)
		.filter(name => Boolean(name))
		.join(", ");

	if (!usersThatSawMessage.length) return null;
	return (
		<span
			className={classNames("text-secondary d-block pr-2", {
				"text-right": message.sender_name === presentUserFullName,
			})}
		>
			{t("seen_by")} {usersThatSawMessage}
		</span>
	);
};

export default SeenMessageText;
