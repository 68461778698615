import React from "react";
import { useTranslation } from "react-i18next";

import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
import Alert from "components/core/Alert";
import DynamicSelect from "components/DynamicSelect";
import FilesUpload from "components/FilesUpload";

const ToolForm = ({
	onSubmit,
	handleInput,
	formSubmitButton,
	formData,
	error,
	setImages,
	setDocuments,
}) => {
	const { t } = useTranslation();

	return (
		<form onSubmit={onSubmit}>
			<div className="d-flex mb-3">
				<div className="col-6 pl-0 pr-3">
					<InputWrapper label={t("edit_tool_name")} htmlFor="name">
						<Input
							name="name"
							placeholder={t("input_label_name")}
							onChange={handleInput}
							defaultValue={formData?.name}
							required
							light
						/>
					</InputWrapper>
				</div>
				<div className="col-6 pl-0 pl-3">
					<InputWrapper
						label={t("edit_tool_serial_number")}
						htmlFor="value"
						className="mb-3"
					>
						<Input
							name="value"
							placeholder={t("input_label_value")}
							onChange={handleInput}
							defaultValue={formData?.value}
							required
							light
						/>
					</InputWrapper>
				</div>
			</div>

			<div className="d-flex mb-3">
				<div className="col-6 pl-0 pr-3">
					<InputWrapper
						label={t("task_user")}
						htmlFor="user_id"
						className="mb-3"
					>
						<DynamicSelect
							endpoint="/users"
							labelProperty="full_name"
							defaultValue={formData?.user_id}
							defaultSelection={{
								label: t("app_warehouse"),
								value: 0,
							}}
							onChange={e => {
								handleInput({
									target: {
										name: "user_id",
										value: e ? e.value : 0,
									},
								});
								handleInput({
									target: {
										name: "user_full_name",
										value: e ? e.label : "",
									},
								});
							}}
						/>
					</InputWrapper>
				</div>

				<div className="col-6 pr-0 pl-3">
					<InputWrapper label={t("task_object")} htmlFor="object_id">
						<DynamicSelect
							endpoint="/objects"
							labelProperty="name"
							placeholder={t("edit_task_select_object")}
							defaultValue={
								formData?.object_id > 0
									? formData?.object_id
									: null
							}
							onChange={e =>
								handleInput({
									target: {
										name: "object_id",
										value: e ? e.value : -1,
									},
								})
							}
						/>
					</InputWrapper>
				</div>
			</div>

			<div className="divider divider--horizontal mb-3"></div>

			<InputWrapper label={t("attachments")}>
				<FilesUpload
					defaultFilesStr={formData?.image}
					setFiles={setImages}
				/>
			</InputWrapper>

			<InputWrapper label={t("files")}>
				<FilesUpload
					type="documents"
					defaultFilesStr={formData?.file}
					setFiles={setDocuments}
				/>
			</InputWrapper>

			{error && <Alert success={false}>{error}</Alert>}

			<button ref={formSubmitButton} className="d-none">
				Submit form
			</button>
		</form>
	);
};

export default ToolForm;
