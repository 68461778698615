import CONSTS from "consts";

function getSubchannelTitle(subchannel, user = null) {
	if (!subchannel) return;
	if (subchannel.channel_title?.length > 0) return subchannel.channel_title;
	if (!subchannel.assigners) return;
	return subchannel.assigners
		.filter(assigner => assigner.user_id !== user.userData.id)
		.map(assigner => assigner.full_name)
		.join(", ");
}

function filterSubchannels({
	subchannels,
	searchText = "",
	chatType = null,
	user = null,
}) {
	return subchannels
		.filter(subchannel => {
			return (
				!subchannel.task &&
				(user
					? getSubchannelTitle(subchannel, user)
							.toLowerCase()
							.includes(searchText.toLowerCase())
					: true) &&
				(chatType
					? chatType === CONSTS.OBJECTS
						? subchannel.channel_object_id !== null
						: !subchannel.channel_object_id
					: true) &&
				!subchannel.channel_task_id
			);
		})
		.sort((a, b) => parseInt(b.updated_at) - parseInt(a.updated_at));
}

async function sendAndProcessMessageRequest({
	api,
	chat,
	chatDispatch,
	channel_id,
	requestData,
	user,
	setMessageInput,
	setSending,
}) {
	await api
		.post("/chat", {
			...requestData,
			channel_id,
		})
		.then(response => {
			const dates = {
				created_at: (+new Date()).toString(),
				updated_at: (+new Date()).toString(),
			};

			chatDispatch({
				type: "set_subchannels_callback",
				payload: {
					callback: subchannels =>
						subchannels.map(subchannel =>
							subchannel.channel_id === channel_id
								? {
										...subchannel,
										...requestData,
										...dates,
								  }
								: subchannel
						),
				},
			});
			if (
				chat.activeSubchannels.find(
					activeSubchannel =>
						activeSubchannel.channel_id === channel_id
				)
			) {
				chatDispatch({
					type: "set_seen_messages",
					payload: {
						channel_id,
						seenMessages: response.data.data.seen,
					},
				});
				chatDispatch({
					type: "set_messages_callback",
					payload: {
						channel_id,
						callback: messages => [
							...messages,
							{
								sender_id: user.userData.id,
								sender_name: user.userData.full_name,
								...requestData,
								id: response.data.data.id,
								...dates,
							},
						],
					},
				});
				chatDispatch({
					type: "set_unread_message_id",
					payload: { channel_id, data: null },
				});

				chatDispatch({
					type: "set_additional_offset_callback",
					payload: { channel_id, callback: offset => offset + 1 },
				});
			}
			if (setMessageInput) setMessageInput("");
		})
		// .catch(e => console.log(e))
		.finally(() => {
			if (setSending) setSending(false);
		});
}

function splitByLastDot(text) {
	const index = text.lastIndexOf(".");
	return [text.slice(0, index), text.slice(index + 1)];
}

export {
	getSubchannelTitle,
	filterSubchannels,
	sendAndProcessMessageRequest,
	splitByLastDot,
};
