import React, { useEffect, useContext } from "react";

import LazyImageContext from "contexts/LazyImgContext";
import Spinner from "components/core/Spinner";
import { urltoBlob, compress, filetoDataURL } from "image-conversion";

const LazyImg = ({ src, ...props }) => {
	const [lazyImages, dispatchLazyImages] = useContext(LazyImageContext);

	useEffect(() => {
		if (!lazyImages.cache[src])
			dispatchLazyImages({
				type: "ADD_TO_LOADING",
				payload: src,
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src]);

	useEffect(() => {
		if (!lazyImages.loadingList.length) return;
		if (lazyImages.loadingList[0] !== src) return;

		urltoBlob(src).then(blob => {
			compress(blob, {
				quality: 0.8,
				type: "image/jpeg",
				height: 63 * 2,
			}).then(compressedBlob => {
				filetoDataURL(compressedBlob).then(imgURL => {
					dispatchLazyImages({
						type: "ADD_TO_CACHE",
						payload: { [src]: imgURL },
					});
					dispatchLazyImages({ type: "REMOVE_TOP_FROM_LOADING" });
				});
			});
		});

		return function cleanup() {
			dispatchLazyImages({ type: "REMOVE_FROM_LOADING", payload: src });
		};
	}, [lazyImages, dispatchLazyImages, src]);

	if (lazyImages.cache[src])
		// eslint-disable-next-line jsx-a11y/alt-text
		return <img src={lazyImages.cache[src]} {...props} />;
	else return <Spinner color="#000" />;
};

export default LazyImg;
