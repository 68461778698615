import React, { useEffect, useRef, useContext, memo } from "react";
import classNames from "classnames";
import MeasurableElementsContext from "contexts/MeasurableElementsContext";

let subHeaderHeight = 0;

const SubHeader = memo(({ children, className, ...props }) => {
	const [, setHeaderSize] = useContext(MeasurableElementsContext);
	const subHeader = useRef();

	useEffect(() => {
		if (
			subHeader?.current &&
			subHeader.current.offsetHeight !== subHeaderHeight
		) {
			subHeaderHeight = subHeader.current.offsetHeight;
			setHeaderSize(size => ({
				...size,
				subHeader: subHeaderHeight,
			}));
		}
	}, [children, setHeaderSize]);

	useEffect(() => {
		return function cleanup() {
			setHeaderSize(size => ({ ...size, subHeader: 0 }));
			subHeaderHeight = 0;
		};
	}, [setHeaderSize]);

	return (
		<div
			ref={subHeader}
			className={classNames("additional-header", className)}
			{...props}
		>
			{children}
		</div>
	);
});

export default SubHeader;
