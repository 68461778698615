import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";

import MeasurableElementsContext from "contexts/MeasurableElementsContext";

const maxHeight = 120;

const TextAreaStyled = styled.textarea`
	max-height: ${maxHeight}px;
	${({ height }) => height && `height: ${height} !important;`}
`;

const AutoTextArea = props => {
	const textAreaRef = useRef(null);
	const [text, setText] = useState(props.value || "");
	const [defaultHeight, setDefaultHeight] = useState(0);
	const [textAreaHeight, setTextAreaHeight] = useState("auto");
	const [parentHeight, setParentHeight] = useState("auto");
	const [, setElementsHeight] = useContext(MeasurableElementsContext);

	useEffect(() => {
		setParentHeight(
			`${
				textAreaRef.current?.scrollHeight < maxHeight
					? textAreaRef.current?.scrollHeight
					: maxHeight
			}px`
		);
		setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
	}, [text, props.defaultValue]);

	useEffect(() => {
		if (defaultHeight > 0) return;
		if (!textAreaRef.current) return;
		setDefaultHeight(textAreaRef.current.clientHeight);
	}, [textAreaHeight, defaultHeight]);

	useEffect(() => {
		if (props.name)
			setElementsHeight(size => ({
				...size,
				[props.name]:
					(parseInt(parentHeight) || defaultHeight) +
					(props.wrapperPadding || 0),
			}));
	}, [
		parentHeight,
		props.name,
		setElementsHeight,
		defaultHeight,
		props.wrapperPadding,
	]);

	useEffect(() => {
		if (props.value === "") {
			setParentHeight("auto");
			setTextAreaHeight("auto");
		}
	}, [props.value]);

	const onChangeHandler = event => {
		setTextAreaHeight("auto");
		setParentHeight(
			`${
				textAreaRef.current?.scrollHeight < maxHeight
					? textAreaRef.current?.scrollHeight
					: maxHeight
			}px`
		);
		setText(event.target.value);

		if (props.onChange) {
			props.onChange(event);
		}
	};

	return (
		<div
			className={props.wrapperClassName}
			style={{
				minHeight: parentHeight,
			}}
		>
			<TextAreaStyled
				{...props}
				ref={textAreaRef}
				rows={1}
				onChange={onChangeHandler}
				height={textAreaHeight}
			/>
		</div>
	);
};

export default AutoTextArea;
