import React, { useState, useContext } from "react";
import classNames from "classnames";

import { getSubchannelTitle } from "pages/Chat/utils";
import MeasurableElementsContext from "contexts/MeasurableElementsContext";
import ChatTemplate from "pages/Chat/ChatTemplate";
import ChatMessages from "pages/Chat/ChatMessages";
import MessageInput from "pages/Chat/MessageInput";
import FileDrop from "components/core/FileDrop";

const Template = props => {
	const [minimized, setMinimized] = useState(false);
	const [elementsHeight] = useContext(MeasurableElementsContext);

	return (
		<div className={classNames("mini-chat", { closed: minimized })}>
			<div
				onClick={() => minimized && setMinimized(false)}
				className="header"
			>
				<div className="d-flex align-items-center">
					<h2>{getSubchannelTitle(props.subchannel, props.user)}</h2>
				</div>
				<div>
					<button
						onClick={() => setMinimized(minimized => !minimized)}
					>
						{/* <i className="fas fa-window-minimize"></i> */}
						<svg height="20px" width="20px" viewBox="-4 -4 24 24">
							<line
								stroke="#fff"
								strokeLinecap="round"
								strokeWidth="2"
								x1="2"
								x2="14"
								y1="8"
								y2="8"
							></line>
						</svg>
					</button>
					<button onClick={props.closeChat}>
						{/* <i className="fas fa-times"></i> */}
						<svg height="20px" width="20px" viewBox="-4 -4 24 24">
							<line
								stroke="#fff"
								strokeLinecap="round"
								strokeWidth="2"
								x1="2"
								x2="14"
								y1="2"
								y2="14"
							></line>
							<line
								stroke="#fff"
								strokeLinecap="round"
								strokeWidth="2"
								x1="2"
								x2="14"
								y1="14"
								y2="2"
							></line>
						</svg>
					</button>
				</div>
			</div>
			{!minimized && (
				<div className="body">
					<FileDrop
						onDrop={props.onDrop}
						className="bg-white"
						style={{
							marginBottom:
								elementsHeight[
									`messageInput${props.subchannel.id}`
								] || 0,
						}}
					>
						<ChatMessages
							messages={props.subchannel.messages}
							unreadMessageId={props.subchannel.unreadMessageId}
							seenMessages={props.subchannel.seenMessages}
							setPage={props.setPage}
							fetchingMessages={props.fetchingMessages}
							activeSubchannel={props.subchannel}
							channel_id={props.subchannel.id}
							setForwardModal={props.setForwardModal}
							isStickedChat
						/>
					</FileDrop>
					<MessageInput
						sendMessage={props.sendMessage}
						messageInput={props.messageInput}
						setMessageInput={props.setMessageInput}
						openUploadImagesModal={props.openUploadImagesModal}
						openUploadFilesModal={props.openUploadFilesModal}
						channel_id={props.subchannel.id}
					/>
				</div>
			)}
		</div>
	);
};

const StickedChat = ({ subchannel }) => {
	return <ChatTemplate subchannel={subchannel} Template={Template} />;
};

export default StickedChat;
