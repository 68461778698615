import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import UserForm from "./UserForm";
import UserContext from "contexts/UserContext";

const EditUserModal = ({ show, disableModal, setUsers, user }) => {
	const { t } = useTranslation();
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData, handleInput] = useForm(user);
	const formSubmitButton = useRef();
	const [userCtx] = useContext(UserContext);

	useEffect(() => {
		setFormData(user);
	}, [show, user, setFormData]);

	const updateUser = e => {
		e.preventDefault();

		if (saving) return;
		if (user.role === "SUPER" && user.id !== userCtx.userData.id) {
			NotificationManager.error(t("super_user_edit_error"));
			return;
		}
		setSaving(true);
		setError(null);

		const formDataCopy = { ...formData };
		if (user.role === "SUPER") {
			formDataCopy["role"] = "SUPER";
			setFormData(formData => ({ ...formData, role: "SUPER" }));
		}

		api.put("/user", {
			...formDataCopy,
			user_id: formDataCopy.id,
		})
			.then(response => {
				setSaving(false);
				if (response.data.error) setError(response.data.error);
				else {
					setUsers(users => {
						const usersCopy = [...users];
						const foundUserIndex = usersCopy.findIndex(
							user => user.id === formDataCopy.id
						);
						if (
							foundUserIndex === null ||
							foundUserIndex === undefined
						)
							return usersCopy;
						usersCopy[foundUserIndex] = formDataCopy;
						return usersCopy;
					});
					disableModal();
				}
			})
			.catch(() => {
				NotificationManager.error(t("error_unknown"));
				setSaving(false);
			});
	};

	return (
		<Modal
			icon
			title={t("edit_user_navigation_title")}
			show={Boolean(show)}
			disableModal={disableModal}
			footerButtons={
				<Button
					onClick={() => formSubmitButton.current.click()}
					loading={saving}
					className="btn-primary"
				>
					{t("edit_user_update_button_text")}
				</Button>
			}
		>
			<UserForm
				onSubmit={updateUser}
				handleInput={handleInput}
				formSubmitButton={formSubmitButton}
				formData={formData}
				error={error}
			/>
		</Modal>
	);
};

export default EditUserModal;
