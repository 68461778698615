import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import MeasurableElementsContext from "contexts/MeasurableElementsContext";
import IsMobileContext from "contexts/IsMobileContext";
import Navigation from "./Navigation";
import PlusCircle from "./PlusCircle";
import CreateTaskModal from "./CreateTaskModal";
import { TASKS_PAGE_PATH } from "routes";

const Header = () => {
	const [, setHeaderSize] = useContext(MeasurableElementsContext);
	const phone = useContext(IsMobileContext);
	const [createTaskModal, setCreateTaskModal] = useState(false);

	return (
		<div
			onLoad={e => {
				const {
					currentTarget: { offsetHeight },
				} = e;
				setHeaderSize(size => ({ ...size, base: offsetHeight }));
			}}
			id="header"
			className="container-fluid"
		>
			<div
				className={classNames("d-flex", {
					"justify-content-between": phone,
				})}
			>
				<div
					className={classNames(
						{ "col-4": !phone },
						"pr-0 d-flex justify-content-between align-items-center"
					)}
				>
					<Link id="logo" to={TASKS_PAGE_PATH}>
						<h1
							className={classNames("d-flex align-items-center", {
								"mr-5": phone,
							})}
						>
							<img
								src={require("assets/img/logo.svg")?.default}
								height={phone ? "35px" : null}
								alt="Logo"
							/>
							TasksPad
						</h1>
					</Link>
					<div className="d-flex align-items-center h-100">
						<PlusCircle onClick={() => setCreateTaskModal(true)} />
						<div className="divider divider--vertical ml-4"></div>
					</div>
				</div>
				<div className={!phone ? "col-8" : "d-flex"}>
					<Navigation />
				</div>
			</div>

			<CreateTaskModal
				show={createTaskModal}
				disableModal={() => setCreateTaskModal(false)}
			/>
		</div>
	);
};

export default Header;
