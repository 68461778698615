import React, { useState, useContext } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
// import OutsideClickHandler from "react-outside-click-handler";

import Backdrop from "./Backdrop";
import PlusCircle from "components/PlusCircle";
import ModalLockContext from "contexts/ModalLockContext";
import MeasurableElementsContext from "contexts/MeasurableElementsContext";
import MeasurableDiv from "components/MeasurableDiv";

const mobileUrlAddressBarOffset = 30;

const Modal = ({
	show,
	disableModal,
	title,
	children,
	footerButtons,
	icon,
	textCenter,
	zIndex = 10000,
	className,
}) => {
	const [elementsHeight] = useContext(MeasurableElementsContext);
	const [unlocked, setLock] = useState(true);
	const { t } = useTranslation();

	const modalHeaderName = `modal-header-${title}`;
	const modalFooterName = `modal-footer-${title}`;

	return createPortal(
		<>
			<ModalLockContext.Provider value={[unlocked, setLock]}>
				<Backdrop zIndex={zIndex} show={show} className={className}>
					<CSSTransition
						in={show}
						timeout={200}
						classNames="fade"
						unmountOnExit
					>
						<div
							className="modal d-block show"
							role="dialog"
							aria-modal="true"
						>
							<div
								className={`modal-dialog ${
									textCenter ? "text-center" : ""
								}`}
								role="document"
							>
								{/* <OutsideClickHandler
									onOutsideClick={() =>
										unlocked && show && disableModal()
									}
								> */}
								<div className="modal-content">
									<MeasurableDiv
										staticMeasure
										name={modalHeaderName}
										className="modal-header align-items-center"
									>
										<h5 className="modal-title d-flex align-items-center">
											{icon && (
												<PlusCircle
													className="mr-2"
													modalIcon
												/>
											)}
											{title}
										</h5>
										<button
											onClick={disableModal}
											type="button"
											className="close"
											data-dismiss="modal"
											aria-label="Close"
										>
											<span aria-hidden="true">
												&times;
											</span>
										</button>
									</MeasurableDiv>
									<div
										className="modal-body"
										style={{
											maxHeight: `calc(100vh - 3.5rem - ${
												elementsHeight[
													modalHeaderName
												] || 0
											}px - ${
												elementsHeight[
													modalFooterName
												] || 0
											}px - ${mobileUrlAddressBarOffset}px)`,
										}}
									>
										{children}
									</div>
									{footerButtons && (
										<MeasurableDiv
											staticMeasure
											name={modalFooterName}
											className="modal-footer justify-content-between"
										>
											<button
												onClick={disableModal}
												className="btn btn-cancel"
											>
												{t("cancel")}
											</button>
											<div>{footerButtons}</div>
										</MeasurableDiv>
									)}
								</div>
								{/* </OutsideClickHandler> */}
							</div>
						</div>
					</CSSTransition>
				</Backdrop>
			</ModalLockContext.Provider>
		</>,
		document.getElementById("modal")
	);
};

export default Modal;
