import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import api from "api";

const DynamicSelect = ({
	onChange,
	endpoint,
	labelProperty,
	defaultValue,
	defaultSelection,
	staticFilter,
	staticPrepare,
	isMulti,
	placeholder,
}) => {
	const { t } = useTranslation();
	const [fetched, setFetched] = useState(false);
	const [options, setOptions] = useState([]);
	const [value, setValue] = useState(isMulti ? [] : null);

	useEffect(() => {
		setFetched(false);

		api.get(endpoint).then(response => {
			if (!response.data.error && response.data.data) {
				let fetchedOptions = staticPrepare
					? staticPrepare(response.data.data)
					: [
							...response.data.data.map(item => ({
								label: item[labelProperty],
								value: item.id,
								data: item,
							})),
					  ];
				if (staticFilter) fetchedOptions = staticFilter(fetchedOptions);
				setOptions(
					defaultSelection
						? [defaultSelection, ...fetchedOptions]
						: fetchedOptions
				);
				setFetched(true);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endpoint, labelProperty, defaultSelection]);

	useEffect(() => {
		function findExactOption(defaultValue) {
			return options.find(
				item => parseInt(item.value) === parseInt(defaultValue)
			);
		}

		if (defaultValue && isMulti) {
			// if is array
			setValue(
				defaultValue.map(value => ({
					value,
					label: findExactOption(value)?.label,
				}))
			);
		} else if (defaultValue) {
			setValue({
				value: defaultValue,
				label: findExactOption(defaultValue)?.label,
			});
		} else setValue(defaultSelection || -1);
	}, [defaultValue, options, labelProperty, defaultSelection, isMulti]);

	const onInternalChange = v => {
		setValue(v);
		onChange(v);
	};

	return (
		<Select
			className="dynamic-select w-100"
			classNamePrefix="dynamic-select"
			options={options}
			isLoading={!fetched}
			onChange={onInternalChange}
			isClearable={true}
			value={value}
			isMulti={isMulti}
			placeholder={placeholder}
			menuPosition="fixed"
			noOptionsMessage={() => t("no_options")}
		/>
	);
};

export default DynamicSelect;
