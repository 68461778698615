import React from "react";

import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ color = "#FFF", size = 30, centerVertically }) => {
	return (
		<div
			className={`text-center ${
				centerVertically
					? "min-vh-100 d-flex justify-content-center align-items-center"
					: "my-3"
			}`}
		>
			<ClipLoader size={size + "px"} color={color} loading={true} />
		</div>
	);
};

export default Spinner;
