import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Button from "components/core/Button";
import AutoTextArea from "components/AutoTextArea";

const MessageInput = ({
	sendMessage,
	messageInput,
	setMessageInput,
	openUploadImagesModal,
	openUploadFilesModal,
	channel_id = "",
}) => {
	const [focused, setFocused] = useState(false);
	const { t } = useTranslation();

	const skipEnterOnTextArea = e => {
		if (e.which !== 13 || e.shiftKey) return;
		e.preventDefault();
		sendMessage();
	};

	return (
		<div className="message-input">
			{!focused && (
				<>
					<Button
						onClick={openUploadFilesModal}
						className="message-input__image-select"
					>
						<i className="fas fa-paperclip fa-fw"></i>
					</Button>
					<Button
						onClick={openUploadImagesModal}
						className="message-input__image-select"
					>
						<i className="far fa-images fa-fw"></i>
					</Button>
				</>
			)}
			<div className="message-input__form-wrapper">
				<form className="message-input__form" onSubmit={sendMessage}>
					<AutoTextArea
						name={`messageInput${channel_id}`}
						wrapperClassName="w-100"
						className="rounded-0 form-control input-dark"
						onKeyPress={skipEnterOnTextArea}
						value={messageInput}
						onChange={e => setMessageInput(e.target.value)}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
						placeholder={t("your_message")}
						wrapperPadding={8}
						rows={1}
					/>
					<Button
						disabled={!messageInput.length}
						className={classNames("message-input__send", {
							"disabled": !messageInput.length,
						})}
					>
						<i className="fas fa-paper-plane"></i>
					</Button>
				</form>
			</div>
		</div>
	);
};

export default MessageInput;
