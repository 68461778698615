import React from "react";
import { useTranslation } from "react-i18next";

import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
import Alert from "components/core/Alert";
import Select from "react-select";

const UserForm = ({
	onSubmit,
	handleInput,
	formSubmitButton,
	formData,
	error,
}) => {
	const { t } = useTranslation();

	const roleOptions = [
		{ value: "WORKER", label: t("app_worker") },
		{ value: "ADMIN", label: t("app_admin") },
	];

	return (
		<form onSubmit={onSubmit}>
			<InputWrapper
				label={t("edit_user_name")}
				htmlFor="full_name"
				className="mb-3"
			>
				<Input
					name="full_name"
					placeholder={t("input_label_fullname")}
					onChange={handleInput}
					defaultValue={formData?.full_name}
					required
					light
				/>
			</InputWrapper>
			<InputWrapper
				label={t("edit_user_username")}
				htmlFor="username"
				className="mb-3"
			>
				<Input
					type="email"
					name="username"
					placeholder={t("input_label_email")}
					onChange={handleInput}
					defaultValue={formData?.username}
					required
					light
				/>
			</InputWrapper>
			<InputWrapper
				label={t("edit_user_password")}
				htmlFor="password"
				className="mb-3"
			>
				<Input
					type="password"
					name="password"
					placeholder={t("input_label_password")}
					onChange={handleInput}
					defaultValue={formData?.password}
					minLength="6"
					required
					light
				/>
			</InputWrapper>
			<InputWrapper
				label={t("edit_user_phone_number")}
				htmlFor="phone_number"
				className="mb-3"
			>
				<Input
					type="phone_number"
					name="phone_number"
					placeholder={t("input_label_phone_number")}
					onChange={handleInput}
					defaultValue={formData?.phone_number}
					light
				/>
			</InputWrapper>
			<InputWrapper
				label={t("edit_user_role")}
				htmlFor="role"
				className="mb-3"
			>
				<Select
					className="dynamic-select w-100"
					name="role"
					onChange={newValue =>
						handleInput({
							target: { name: "role", value: newValue.value },
						})
					}
					value={roleOptions.find(
						option => option.value === formData?.role
					)}
					options={roleOptions}
					isSearchable={false}
					menuPosition="fixed"
					required
				/>
			</InputWrapper>

			{error && <Alert success={false}>{error}</Alert>}

			<button ref={formSubmitButton} className="d-none">
				Submit form
			</button>
		</form>
	);
};

export default UserForm;
