import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { STATUSES, getTaskStatusName } from "utils/taskStatuses";
import IconWrapper from "components/IconWrapper";
import ObjectIcon from "components/User";
import TaskStatus from "components/TaskStatus";
import { TASKS_PAGE_PATH } from "routes";

const Task = ({ data, onSelect, active }) => {
	const status = getTaskStatusName(data);

	let fileCount = 0;
	if (data.image?.length) fileCount += data.image?.split(",").length;
	if (data.file?.length) fileCount += data.file?.split(",").length;

	return (
		<Link
			to={`${TASKS_PAGE_PATH}/${data.id}`}
			onClick={onSelect}
			className={classNames([
				"task cursor-pointer d-block",
				{ "active": active },
			])}
		>
			<div className="d-flex">
				<div className="col-9 border-bottom-divider mr-2">
					<p className="task__name">{data.title}</p>
					<div className="task__author mb-2">
						{Boolean(data.user_full_name) && (
							<ObjectIcon username={data.user_full_name} />
						)}
					</div>
				</div>
				<div className="col-3">
					<div className="task__secondary-block color-primary">
						<i className="far fa-comments mr-2"></i> (
						{data.channel?.chats_count || 0})
					</div>
					<div className="task__secondary-block task__secondary-block__last color-secondary">
						<i className="fas fa-paperclip mr-2"></i>({fileCount})
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-between mt-2">
				<div className="mr-2">
					<TaskStatus data={data} />
				</div>
				<div className="task__date d-flex align-items-center">
					<IconWrapper className="mr-2">
						<i className="fas fa-calendar"></i>
					</IconWrapper>
					<span className="text-nowrap">
						{status === STATUSES.Finished &&
							new Date(
								parseInt(data.finish_date)
							).toLocaleDateString("lt-LT")}
						{status === STATUSES.Accepted &&
							new Date(
								parseInt(data.accept_date)
							).toLocaleDateString("lt-LT")}
						{status === STATUSES.Declined &&
							new Date(
								parseInt(data.declined_date)
							).toLocaleDateString("lt-LT")}
						{status === STATUSES.Created && (
							<>
								{new Date(
									parseInt(data.date_from)
								).toLocaleDateString("lt-LT")}{" "}
								-{" "}
								{new Date(
									parseInt(data.date_to)
								).toLocaleDateString("lt-LT")}{" "}
							</>
						)}
					</span>
				</div>
			</div>
		</Link>
	);
};

export default Task;
