import React, { useContext } from "react";

import UserContext from "contexts/UserContext";
import IconWrapper from "components/IconWrapper";

const ObjectItem = ({ onClick, object, onDeleteClick }) => {
	const [userCtx] = useContext(UserContext);

	const onInternalDeleteClick = e => {
		e.stopPropagation();
		onDeleteClick();
	};

	return (
		<div onClick={onClick} className="object-row">
			<div className="d-flex">
				<IconWrapper size={4} className="mr-3">
					<i className="fas fa-warehouse"></i>
				</IconWrapper>
				<div className="d-flex flex-column">
					<h3>{object.name}</h3>
					<span>{object.address}</span>
				</div>
			</div>
			<div>
				{userCtx.userData.role === "SUPER" && (
					<div
						className="d-inline mr-4"
						onClick={onInternalDeleteClick}
					>
						<i className="fas fa-trash-alt"></i>
					</div>
				)}
				<i className="fas fa-long-arrow-alt-right"></i>
			</div>
		</div>
	);
};

export default ObjectItem;
