import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import api from "api";

import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import UserForm from "./UserForm";

const defaultFormData = { role: "WORKER" };

const CreateUserModal = ({ show, disableModal, setUsers }) => {
	const { t } = useTranslation();
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData, handleInput] = useForm(defaultFormData);
	const formSubmitButton = useRef();

	useEffect(() => {
		setFormData(defaultFormData);
	}, [show, setFormData]);

	const createUser = e => {
		e.preventDefault();

		if (saving) return;
		setSaving(true);
		setError(null);

		api.post("/user", {
			...formData,
		})
			.then(response => {
				setSaving(false);
				if (response.data.error) setError(response.data.error);
				else {
					setUsers(users => [
						...users,
						{ id: response.data.data, ...formData },
					]);
					disableModal();
				}
			})
			.catch(() => {
				NotificationManager.error(t("error_unknown"));
				setSaving(false);
			});
	};

	return (
		<Modal
			icon
			title={t("edit_user_navigation_title_create")}
			show={show}
			disableModal={disableModal}
			footerButtons={
				<Button
					onClick={() => formSubmitButton.current.click()}
					loading={saving}
					className="btn-primary"
				>
					{t("edit_user_create_button_text")}
				</Button>
			}
		>
			<UserForm
				onSubmit={createUser}
				handleInput={handleInput}
				formSubmitButton={formSubmitButton}
				formData={formData}
				error={error}
			/>
		</Modal>
	);
};

export default CreateUserModal;
