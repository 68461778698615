import React from "react";
import { useTranslation } from "react-i18next";

import ChatTemplate from "pages/Chat/ChatTemplate";
import ChatMessages from "pages/Chat/ChatMessages";
import MessageInput from "pages/Chat/MessageInput";
import FileDrop from "components/core/FileDrop";

const Template = props => {
	const { t } = useTranslation();

	return (
		<div className="task-chat-wrapper">
			<h4 className="text-primary font-weight-normal">
				<i className="far fa-comments mr-1"></i> {t("task_discussion")}
			</h4>
			<FileDrop onDrop={props.onDrop}>
				<ChatMessages
					setPage={props.setPage}
					fetchingMessages={props.fetchingMessages}
					activeSubchannel={props.subchannel}
					setForwardModal={props.setForwardModal}
				/>
				<MessageInput
					sendMessage={props.sendMessage}
					messageInput={props.messageInput}
					setMessageInput={props.setMessageInput}
					openUploadImagesModal={props.openUploadImagesModal}
					openUploadFilesModal={props.openUploadFilesModal}
				/>
			</FileDrop>
		</div>
	);
};

const TaskInternalChat = ({ subchannel }) => {
	return <ChatTemplate subchannel={subchannel} Template={Template} />;
};

export default TaskInternalChat;
