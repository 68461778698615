import React from "react";
import TaskStatus from "components/TaskStatus";

const TaskStatusFilter = ({ status, setTasksType }) => {
	return (
		<div
			key={status.value}
			onClick={() => setTasksType(status.value)}
			className="cursor-pointer mr-2 my-1"
		>
			<TaskStatus status={status.name} />
		</div>
	);
};

export default TaskStatusFilter;
