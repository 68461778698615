import React from "react";
import { useHistory } from "react-router-dom";
import { baseURL } from "api";

import ObjectIcon from "components/User";
import LazyImg from "components/core/LazyImg";

const STATUS_COLORS = {
	REMOVED: "tool-removed",
	BROKEN: "tool-broken",
	IN_REPAIR: "tool-in_repair",
	AVAILABLE: "tool-available",
	TAKEN: "tool-taken",
};

const Tool = React.memo(({ data, setEditToolModal, setRemoveToolModal, t }) => {
	const history = useHistory();

	let status = data.status?.toLowerCase();
	if (status === "in_repair") status = "repairing";

	return (
		<div
			onClick={() =>
				data.status !== "REMOVED"
					? setEditToolModal(data)
					: history.push(`/tools/${data.id}/history`)
			}
			className="tool-row"
		>
			<div className="d-flex w-100 overflow-hidden">
				<div className="img-wrapper mr-3">
					{data.image ? (
						<LazyImg
							src={`${baseURL}/uploads/${
								data.image.split(",")[0]
							}`}
							className="img-fluid object-fit-cover w-100"
							width="63"
							height="63"
							alt="Tool"
						/>
					) : (
						<img
							src={require("assets/img/image_icon.svg")?.default}
							className="img-fluid object-fit-cover w-100"
							alt="Tool"
						/>
					)}
				</div>
				<div className="text-wrapper d-flex flex-column justify-content-between">
					<div className="d-flex flex-column">
						<div className="d-flex justify-content-between">
							<h3>{data.name}</h3>
							{data.status !== "REMOVED" && (
								<button
									onClick={e => {
										e.stopPropagation();
										setRemoveToolModal(data.id);
									}}
									className="btn"
								>
									<i className="far fa-trash-alt"></i>
								</button>
							)}
						</div>
						<span className={STATUS_COLORS[data.status]}>
							{t(`tools_cell_${status}`) + " "}
							<strong>{data.value}</strong>
						</span>
					</div>
					{data.status !== "AVAILABLE" && data.user_full_name && (
						<ObjectIcon>{data.user_full_name}</ObjectIcon>
					)}
				</div>
			</div>
		</div>
	);
});

export default Tool;
