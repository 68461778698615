import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import OptionButton from "./OptionButton";

const LANGUAGES = ["en", "lt", "ru"];

const LanguageSelectButton = () => {
	const { t, i18n } = useTranslation();
	const [languageSelect, setLanguageSelect] = useState(false);

	const changeLanguage = lng => {
		localStorage.setItem("language", lng);
		i18n.changeLanguage(lng);
	};

	return (
		<OptionButton
			onClick={() => setLanguageSelect(select => !select)}
			title={t("language")}
			iconClassName="fas fa-globe"
			CustomRightSide={() => {
				return (
					<div className="language-select mr-3">
						<div className="active d-flex align-items-center">
							<img
								src={
									require(`assets/img/languages/${i18n.language}.gif`)
										?.default
								}
								alt={i18n.language}
								className="mr-3"
							/>
							{
								{
									en: "English",
									lt: "Lietuvių",
									ru: "Русский",
								}[i18n.language]
							}
						</div>
						{languageSelect && (
							<div className="options">
								{LANGUAGES.map(language => {
									if (i18n.language === language) return null;
									return (
										<div
											key={language}
											onClick={() =>
												changeLanguage(language)
											}
										>
											<img
												src={
													require(`assets/img/languages/${language}.gif`)
														?.default
												}
												alt={language}
											/>
										</div>
									);
								})}
							</div>
						)}
					</div>
				);
			}}
		/>
	);
};

export default LanguageSelectButton;
