import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { baseURL } from "api";

import { splitByLastDot } from "pages/Chat/utils";
import ZoomedImage from "./ZoomedImage";
import ModalLockContext from "contexts/ModalLockContext";

const downloadb64 = (filename, dataUrl) => {
	const downloadLink = document.createElement("a");
	downloadLink.href = dataUrl;
	downloadLink.download = filename;
	downloadLink.click();
};

const ZoomableImage = ({
	filename,
	title,
	imageURL,
	zIndex,
	channelId,
	document = false,
	column,
}) => {
	const [zoomed, setZoomed] = useState(false);
	const modalLock = useContext(ModalLockContext);

	useEffect(() => {
		if (modalLock?.length) modalLock[1](!zoomed);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zoomed]);

	let url = imageURL || `${baseURL}/uploads/${filename}`;
	if (document) {
		let extension = filename?.name || filename;
		if (extension) {
			extension = splitByLastDot(extension);
			if (extension.length > 1) extension = extension[1];
			try {
				url = require(`assets/img/icons/${extension}.svg`)?.default;
			} catch {
				url = require("assets/img/icons/google-docs.svg")?.default;
			}
		}
	}

	const downloadFile = src => {
		let url = src;
		if (document) url = `${baseURL}/uploads/${filename}`;

		fetch(url)
			.then(res => res.blob())
			.then(blob => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () =>
					downloadb64(
						filename.length ? filename : "image",
						reader.result
					);
			});
	};

	let image;

	if (document) {
		image = (
			<div
				// onClick={() => setZoomed(true)}
				onClick={downloadFile}
				className={classNames(
					"document-image d-flex align-items-center cursor-pointer",
					{ "flex-column": column }
				)}
			>
				<img
					src={url}
					alt={filename}
					title={title}
					className="document"
				/>
				<span className="document-name font-weight-bold">
					{filename?.name || filename}
				</span>
			</div>
		);
	} else {
		image = (
			<img
				src={url}
				alt={filename}
				onClick={() => setZoomed(true)}
				title={title}
				className="cursor-pointer"
			/>
		);
	}

	return (
		<>
			{image}

			{zoomed && (
				<ZoomedImage
					url={url}
					filename={filename}
					setZoomed={setZoomed}
					channelId={channelId}
					zIndex={zIndex}
					isDocument={document}
					downloadFile={downloadFile}
				/>
			)}
		</>
	);
};

export default ZoomableImage;
