import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/core/Modal";
import Button from "components/core/Button";
import DynamicSelect from "components/DynamicSelect";
import { getSubchannelTitle } from "./utils";

const ForwardMessageModal = ({
	forwardModal,
	setForwardModal,
	forwarding,
	user,
	channelId,
	forwardCallback,
}) => {
	const { t } = useTranslation();
	const [channelsToForward, setChannelsToForward] = useState([]);

	const forward = () => {
		if (forwardCallback) forwardCallback(channelsToForward);
	};

	return (
		<Modal
			title={t("image_forward")}
			className="forward-modal"
			show={forwardModal}
			disableModal={() => setForwardModal(false)}
			footerButtons={
				<Button loading={forwarding} onClick={forward}>
					{t("image_forward")}
				</Button>
			}
		>
			<DynamicSelect
				endpoint="/sub_channels"
				labelProperty="channel_id"
				placeholder={t("edit_task_select_user")}
				onChange={subchannels =>
					setChannelsToForward(
						subchannels
							? subchannels.map(subchannel => subchannel.value)
							: []
					)
				}
				staticPrepare={subchannels =>
					subchannels
						.filter(
							subchannel => subchannel.channel_id !== channelId
						)
						.map(subchannel => ({
							value: subchannel.channel_id,
							label: getSubchannelTitle(subchannel, user),
						}))
				}
				isMulti
			/>
		</Modal>
	);
};

export default ForwardMessageModal;
