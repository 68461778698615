import React, { useState } from "react";
import classNames from "classnames";

const Switch = ({ options, onChange, small, ...props }) => {
	const [value, setValue] = useState(options[0].value);

	return (
		<div className="switch" {...props}>
			{options.map(option => {
				return (
					<div
						key={option.value}
						onClick={() => {
							setValue(option.value);
							if (onChange) onChange(option.value);
						}}
						className={classNames(
							"switch__option",
							{
								active: value === option.value,
							},
							{ "switch__option--small": small }
						)}
					>
						{option.label}
					</div>
				);
			})}
			<div
				className={classNames("active-bg", {
					right: value === options[1].value,
				})}
			></div>
		</div>
	);
};

export default Switch;
