import React from "react";
import classNames from "classnames";

import IconWrapper from "components/IconWrapper";

const User = ({
	username,
	size,
	iconWrapperClassName = "mr-2",
	title,
	children,
	object,
}) => {
	return (
		<div className="user d-flex align-items-center" title={title}>
			<IconWrapper className={iconWrapperClassName} size={size}>
				<i
					className={classNames(
						"fas user-icon",
						{ "fa-user": !object },
						{ "fa-warehouse": object }
					)}
				></i>
			</IconWrapper>{" "}
			{children ? children : username}
		</div>
	);
};

export default User;
