import React from "react";
import classNames from "classnames";

const InputWrapper = ({
	className,
	label,
	htmlFor,
	children,
	childWrapperClassName,
	notInputGroup,
	labelClassName,
}) => {
	return (
		<div className={className}>
			{!!label && (
				<label
					className={classNames("mb-3", labelClassName)}
					htmlFor={htmlFor}
				>
					{label}
				</label>
			)}
			<div
				className={classNames(childWrapperClassName, {
					"input-group": !notInputGroup,
				})}
			>
				{children}
			</div>
		</div>
	);
};

export default InputWrapper;
