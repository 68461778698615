import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "api";

import LoadingWrapper from "components/core/LoadingWrapper";
import Alert from "components/core/Alert";
import ObjectIcon from "components/User";
import SubHeader from "components/SubHeader";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";

const History = ({ match }) => {
	const { t } = useTranslation();
	const [fetching, setFetching] = useState(true);
	const [history, setHistory] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		api.get("/history", { params: { material_id: match.params.id } })
			.then(response => {
				if (response.data.error) setError(response.data.error);
				else setHistory(response.data.data);
			})
			.catch(() => setError(t("error_unknown")))
			.finally(() => setFetching(false));
	}, [match.params.id, t]);

	return (
		<div className="history-wrapper bg-secondary">
			<div className="container-fluid">
				<SubHeader className="d-flex align-items-center">
					<Link to="/tools" className="btn btn-back">
						<i className="fas fa-long-arrow-alt-left"></i>
					</Link>
					<h2>{t("tool_history_navigation_title")}</h2>
				</SubHeader>

				<FullScreenHeightWrapper>
					<LoadingWrapper fetched={!fetching} size={120}>
						<>
							{error ? (
								<Alert success={false}>{error}</Alert>
							) : (
								<>
									{!history.length && (
										<h4 className="text-center py-3">
											{t("tools_list_empty")}
										</h4>
									)}
									{history
										.map((item, i) => {
											let statusText = `${t(
												"tool_history_tool_is"
											)} `;

											if (i === 0)
												statusText += t(
													"tool_history_created_by"
												);
											else {
												if (
													item.material_status ===
													"AVAILABLE"
												)
													statusText += t(
														"tool_history_updated_by"
													);
												else if (
													item.material_status ===
													"BROKEN"
												)
													statusText = `${t(
														"tool_history_tool_marked"
													)} ${t(
														"tool_history_broken_by"
													)}`;
												else if (
													item.material_status ===
													"IN_REPAIR"
												)
													statusText = `${t(
														"tool_history_tool_moved"
													)} ${t(
														"tool_history_repair_by"
													)}`;
												else if (
													item.material_status ===
													"TAKEN"
												)
													statusText += t(
														"tool_history_taken_by"
													);
												else if (
													item.material_status ===
													"REMOVED"
												)
													statusText += t(
														"tool_history_updated_by"
													);
											}

											return (
												<div
													key={item.date}
													className="main-row"
												>
													<div className="col-3">
														<span className="subheading">
															{item.date
																? new Date(
																		parseInt(
																			item.date
																		)
																  ).toLocaleDateString(
																		"lt-LT"
																  )
																: ""}
														</span>
														<h3>
															{item.user_full_name ||
																t(
																	"app_warehouse"
																)}
														</h3>
													</div>
													<div className="col-3">
														<span className="subheading">
															{statusText}
														</span>
														<ObjectIcon
															username={
																item.history_creator_full_name
															}
														/>
													</div>
													<div className="col-6"></div>
												</div>
											);
										})
										.reverse()}
								</>
							)}
						</>
					</LoadingWrapper>
				</FullScreenHeightWrapper>
			</div>
		</div>
	);
};

export default History;
