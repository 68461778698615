import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import ChatContext from "contexts/ChatContext";
import UserContext from "contexts/UserContext";
import MeasurableElementsContext from "contexts/MeasurableElementsContext";
import MeasurableDiv from "components/MeasurableDiv";
import LoadingWrapper from "components/core/LoadingWrapper";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";
import ObjectIcon from "components/User";
import Switch from "components/Switch";
import CONSTS from "consts";
import { getSubchannelTitle, filterSubchannels } from "pages/Chat/utils";

const ContactsList = () => {
	const [chat, chatDispatch] = useContext(ChatContext);
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [elementsHeight] = useContext(MeasurableElementsContext);
	const [searchText, setSearchText] = useState("");
	const [chatType, setChatType] = useState(CONSTS.USERS);
	const [filteredSubchannels, setFilteredSubchannels] = useState([]);

	useEffect(() => {
		if (!user?.userData?.id) return;
		setFilteredSubchannels(
			filterSubchannels({
				subchannels: chat.subchannels,
				searchText,
				chatType,
				user,
			})
		);
	}, [chat.subchannels, chatType, searchText, user]);

	return (
		<div
			style={{
				paddingTop: elementsHeight.base + "px",
			}}
			className="contacts-list vh-100 d-flex flex-column"
		>
			<MeasurableDiv name="contacts-list-head">
				<div className="mb-2">
					<span className="contacts-title h4">
						{t("chat_contacts")}
					</span>
				</div>
				<div className="px-2 py-1">
					<Switch
						small
						options={[
							{
								value: CONSTS.USERS,
								label: t("conversations_users"),
							},
							{
								value: CONSTS.OBJECTS,
								label: t("conversations_objects"),
							},
						]}
						onChange={newValue => setChatType(newValue)}
					/>
				</div>
			</MeasurableDiv>
			<div className="d-flex justify-content-streth flex-column">
				<FullScreenHeightWrapper
					elements={[
						"base",
						"contacts-list-head",
						"contacts-list-search",
					]}
					className="contacts"
				>
					<div className="w-100">
						<LoadingWrapper fetched={chat?.loaded} size={60}>
							<>
								{!filteredSubchannels.length ? (
									<p className="text-center mt-3 h5">
										{t("empty_conversations")}
									</p>
								) : (
									filteredSubchannels.map((subchannel, i) => (
										<div
											key={i}
											onClick={() => {
												chatDispatch({
													type:
														"set_active_subchannel",
													payload: {
														subchannel_info: subchannel,
													},
												});
											}}
											className="user-row cursor-pointer"
										>
											<ObjectIcon
												username={getSubchannelTitle(
													subchannel,
													user
												)}
												object={
													chatType === CONSTS.OBJECTS
												}
											></ObjectIcon>
										</div>
									))
								)}
							</>
						</LoadingWrapper>
					</div>
				</FullScreenHeightWrapper>
				<MeasurableDiv name="contacts-list-search">
					<div className="search">
						<input
							onChange={e => setSearchText(e.target.value)}
							type="text"
							placeholder={`${t("app_search")}...`}
						/>
					</div>
				</MeasurableDiv>
			</div>
		</div>
	);
};

export default ContactsList;
