import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import UserContext from "contexts/UserContext";
import InputWrapper from "components/core/InputWrapper";
import Input from "components/core/Input";
import AutoTextArea from "components/AutoTextArea";
import Alert from "components/core/Alert";
import DynamicSelect from "components/DynamicSelect";

const ObjectForm = ({
	onSubmit,
	handleInput,
	formSubmitButton,
	formData,
	error,
}) => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);

	return (
		<form onSubmit={onSubmit}>
			<InputWrapper
				label={t("edit_object_name")}
				htmlFor="name"
				className="mb-3"
			>
				<Input
					name="name"
					placeholder={t("input_label_name")}
					onChange={handleInput}
					defaultValue={formData?.name}
					required
					light
				/>
			</InputWrapper>
			<div className="d-flex mb-3">
				<div className="col-6 pl-0 pr-3">
					<InputWrapper
						label={t("edit_object_address")}
						htmlFor="address"
						className="mb-3"
					>
						<Input
							name="address"
							placeholder={t("input_label_address")}
							onChange={handleInput}
							defaultValue={formData?.address}
							required
							light
						/>
					</InputWrapper>
				</div>
				<div className="col-6 pl-0 pl-3">
					<InputWrapper
						label={t("edit_object_description")}
						htmlFor="description"
						className="mb-3"
					>
						<AutoTextArea
							wrapperClassName="w-100"
							className="form-control input-light"
							placeholder={t("input_label_description")}
							onChange={e =>
								handleInput({
									target: {
										name: "description",
										value: e.target.value,
									},
								})
							}
							defaultValue={formData?.description}
							required
							// rows="1"
							// light
						/>
					</InputWrapper>
				</div>
			</div>
			<InputWrapper
				label={t("objects_users_title")}
				htmlFor="assigned_users"
				className="mb-3"
			>
				<DynamicSelect
					endpoint="/users"
					labelProperty="full_name"
					placeholder={t("objects_users_title")}
					defaultValue={
						formData?.assigned_users ||
						formData?.channel?.assigners?.map(
							assigner => assigner.user_id
						) || [user?.userData?.id]
					}
					onChange={newUsers =>
						handleInput({
							target: {
								value: newUsers
									? newUsers.map(user => user.value)
									: [],
								name: "assigned_users",
							},
						})
					}
					isMulti
				/>
			</InputWrapper>

			{error && <Alert success={false}>{error}</Alert>}

			<button ref={formSubmitButton} className="d-none">
				Submit form
			</button>
		</form>
	);
};

export default ObjectForm;
