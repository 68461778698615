import React from "react";
import classNames from "classnames";

const Alert = ({ children, type, success, onClose }) => {
	if (!children) return null;
	const alertType = type
		? `alert-${type}`
		: success
		? "alert-success"
		: "alert-danger";
	return (
		<div
			className={classNames("alert", alertType, {
				"alert-dismissable": Boolean(onClose),
			})}
			role="alert"
		>
			{children}

			{onClose && (
				<button
					onClick={onClose}
					type="button"
					className="close"
					aria-label="Close"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			)}
		</div>
	);
};

export default Alert;
