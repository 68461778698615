import React, { useState, useEffect, useRef } from "react";
import { baseURL } from "api";

const AudioPlayer = ({ url, duration, title }) => {
	const audio = useRef();
	const [playing, setPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);

	useEffect(() => {
		if (!audio) return;
		const callback = () => {
			setCurrentTime(audio.current?.currentTime);
		};
		audio.current.addEventListener("timeupdate", callback);
		const audioEl = audio.current;

		return function cleanup() {
			audioEl.removeEventListener("timeupdate", callback);
		};
	}, [audio]);

	const play = () => {
		setPlaying(true);
		audio.current.play();
	};

	const stop = () => {
		setPlaying(false);
		audio.current.pause();
	};

	const timeDate = new Date(duration - currentTime * 1000);
	timeDate.setHours(0);

	return (
		<div className="audio-player" title={title}>
			<div className="audio-player__controller">
				{playing && !audio.current?.paused && !audio.current?.ended ? (
					<button onClick={stop} className="empty-button">
						<i className="fas fa-pause"></i>
					</button>
				) : (
					<button onClick={play} className="empty-button">
						<i className="fas fa-play"></i>
					</button>
				)}
				<span className="audio-player__curent-time">
					{timeDate.toLocaleTimeString("lt-LT", {
						minute: "2-digit",
						second: "2-digit",
					})}
				</span>
			</div>
			<div
				style={{
					width: `${(currentTime / audio.current?.duration) * 100}%`,
				}}
				className="audio-player__time-slider"
			></div>
			<audio
				src={`${baseURL}/uploads/${url}`}
				className="d-none"
				ref={audio}
				controls
			>
				Your browser does not support the audio element.
			</audio>
		</div>
	);
};

export default AudioPlayer;
