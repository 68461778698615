import React from "react";

import Modal from "./Modal";

const ConfirmModal = ({ show, title, question, setModal, buttons }) => {
	const clickButton = callback => {
		if (callback) callback();
		setModal(false);
	};

	return (
		<Modal
			textCenter
			show={show}
			title={title}
			disableModal={() => setModal(false)}
		>
			<>
				{!!question && <p>{question}</p>}
				{buttons.map((btn, i) => (
					<button
						key={i}
						onClick={() => clickButton(btn.callback)}
						className={`btn mr-2 ${btn.className || ""}`}
					>
						{btn.name}
					</button>
				))}
			</>
		</Modal>
	);
};

export default ConfirmModal;
