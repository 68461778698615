import React from "react";
import classNames from "classnames";
import styled from "styled-components";

const StyledInputPrepend = styled.div`
	.input-group-text {
		font-size: 1.4375rem;
		font-weight: normal;
		border-radius: 10px;
		background-color: ${({ dark }) => (dark ? "#eff1f4" : "white")};
		border: ${({ dark }) => (dark ? "none" : "1px solid #BDBDBF")};
	}
`;

const Input = ({ dark, light, className, notFormControl, icon, ...props }) => {
	const input = (
		<input
			className={classNames([
				className,
				{ "form-control": !notFormControl },
				{ "input-dark": dark || !light },
				{ "input-light": light },
			])}
			{...props}
		/>
	);

	if (icon)
		return (
			<>
				<StyledInputPrepend
					dark={dark || !light}
					className="input-group-prepend"
				>
					<span className="input-group-text">{icon}</span>
				</StyledInputPrepend>
				{input}
			</>
		);
	else return input;
};

export default Input;
