import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import SwiperCore, { Pagination, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper.scss";
import "../../../node_modules/swiper/components/pagination/pagination.scss";
import api from "api";

import { TASKS_PAGE_PATH } from "routes";
import ChatContext from "contexts/ChatContext";
import { STATUSES, getTaskStatusName } from "utils/taskStatuses";
import UserContext from "contexts/UserContext";
import uploadImages from "utils/uploadImages";
import IconWrapper from "components/IconWrapper";
import Input from "components/core/Input";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import ObjectIcon from "components/User";
import ConfirmModal from "components/core/ConfirmModal";
import Backdrop from "components/core/Backdrop";
import Spinner from "components/core/Spinner";
import EditTaskModal from "./EditTaskModal";
import TaskStatus from "components/TaskStatus";
import TaskInternalChat from "./TaskInternalChat";
import ZoomableImage from "components/ZoomableImage";

SwiperCore.use([Pagination, Controller]);

const TaskInternal = ({ activeTask, setActiveTask, setTasks }) => {
	const { t } = useTranslation();
	const [chat, chatDispatch] = useContext(ChatContext);
	const [user] = useContext(UserContext);
	const [editTaskModal, setEditTaskModal] = useState(false);
	const [removeTaskModal, setRemoveTaskModal] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [declineTaskModal, setDeclineTaskModal] = useState(false);
	const [declineComment, setDeclineComment] = useState("");
	const [loading, setLoading] = useState(false);
	const formSubmitButton = useRef();

	const [controlledSwiper, setControlledSwiper] = useState(null);

	useEffect(() => {
		const channel = { ...activeTask.channel, task: true };
		if (!channel.id) return;

		channel["channel_id"] = channel.id;

		chatDispatch({
			type: "set_subchannels_callback",
			payload: {
				callback: subchannels => {
					if (
						!subchannels.find(
							subchannel =>
								subchannel.channel_id === channel.channel_id
						)
					) {
						return [...subchannels, channel];
					}
					return subchannels;
				},
			},
		});

		// ADD TO ALL ACTIVE SUBCHANNELS
		chatDispatch({
			type: "set_active_subchannel",
			payload: {
				isChatPage: true,
				subchannel_info: channel,
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTask.id]);

	useEffect(() => {
		setDeclineComment("");
	}, [declineTaskModal]);

	const removeTask = async () => {
		if (removing) return;
		if (
			activeTask.creator_id !== user.userData.id ||
			(user.userData.role !== "ADMIN" && user.userData.role !== "SUPER")
		)
			return NotificationManager.error(
				"Only admin that is creator of task can delete task"
			);

		setRemoving(true);

		await uploadImages([], activeTask.image);

		api.delete("/task", { params: { task_id: activeTask.id } })
			.then(response => {
				if (response.data.error)
					NotificationManager.error(response.data.error);
				else {
					NotificationManager.success("Task succesfully deleted");
					setTasks(tasks => ({
						...tasks,
						list: tasks.list.filter(
							task => task.id !== activeTask.id
						),
					}));
					setActiveTask(null);
				}
			})
			.catch(() => NotificationManager.error(t("app_server_error")))
			.finally(() => setRemoving(false));
	};

	const acceptTask = () => {
		if (loading) return;
		setLoading(true);

		api.put("/task/accept", { task_id: activeTask.id })
			.then(response => {
				setActiveTask({ ...activeTask, ...response.data.data });
				setTasks(tasks => ({
					...tasks,
					list: tasks.list.map(task =>
						task.id === activeTask.id
							? { ...task, ...response.data.data }
							: task
					),
				}));
			})
			.catch(() => NotificationManager.error(t("error_unknown")))
			.finally(() => setLoading(false));
	};

	const declineTask = e => {
		e.preventDefault();

		if (loading) return;
		setLoading(true);

		api.put("/task/decline", {
			task_id: activeTask.id,
			comment: declineComment,
		})
			.then(response => {
				setActiveTask(response.data.data);
				setTasks(tasks => ({
					...tasks,
					list: tasks.list.map(task =>
						task.id === activeTask.id ? response.data.data : task
					),
				}));
			})
			.catch(() => NotificationManager.error(t("error_unknown")))
			.finally(() => {
				setDeclineTaskModal(false);
				setLoading(false);
			});
	};

	const finishTask = () => {
		if (loading) return;
		setLoading(true);

		api.put("/task/finish", {
			task_id: activeTask.id,
		})
			.then(response => {
				setActiveTask(response.data.data);
				setTasks(tasks => ({
					...tasks,
					list: tasks.list.map(task =>
						task.id === activeTask.id ? response.data.data : task
					),
				}));
			})
			.catch(() => NotificationManager.error(t("error_unknown")))
			.finally(() => setLoading(false));
	};

	const imageSlider = Boolean(activeTask.image) && (
		<Swiper
			onSwiper={setControlledSwiper}
			spaceBetween={50}
			slidesPerView={1}
			pagination={{ clickable: true }}
		>
			{activeTask.image.split(",").map(image => (
				<SwiperSlide key={image}>
					<ZoomableImage title={image} filename={image} />
				</SwiperSlide>
			))}
			<button
				onClick={() => controlledSwiper && controlledSwiper.slidePrev()}
				className="swiper-slide-button swiper-previous-slide"
			>
				<i className="fas fa-arrow-circle-left"></i>
			</button>
			<button
				onClick={() => controlledSwiper && controlledSwiper.slideNext()}
				className="swiper-slide-button swiper-next-slide"
			>
				<i className="fas fa-arrow-circle-right"></i>
			</button>
		</Swiper>
	);

	let body = (
		<>
			<div className="d-flex justify-content-between mt-2">
				<div className="d-flex">
					{Boolean(activeTask.user_full_name) && (
						<ObjectIcon username={activeTask.user_full_name} />
					)}
				</div>
				<div className="text-right">
					<button
						onClick={() => setEditTaskModal(true)}
						className="btn"
					>
						<i className="fas fa-edit mr-2"></i>{" "}
						{t("task_edit_button_text")}
					</button>
					<button
						onClick={() => setRemoveTaskModal(true)}
						className="btn ml-2"
					>
						<i className="far fa-trash-alt mr-2"></i>{" "}
						{t("task_remove_button_text")}
					</button>
				</div>
			</div>
			<div className="d-flex justify-content-between mb-1">
				<div></div>
				<div>
					<Link
						to={`${TASKS_PAGE_PATH}/${activeTask.id}/history`}
						className="task-history-link btn"
					>
						<i className="fas fa-history mr-2"></i>
						{t("task_history_button_text")}
					</Link>
				</div>
			</div>
			<EditTaskModal
				show={editTaskModal}
				disableModal={() => setEditTaskModal(false)}
				setActiveTask={setActiveTask}
				setTasks={setTasks}
				task={activeTask}
			/>
			<ConfirmModal
				show={removeTaskModal}
				setModal={setRemoveTaskModal}
				title={t("task_want_to_remove")}
				buttons={[
					{
						name: t("task_want_to_remove_yes"),
						className: "btn-primary",
						callback: removeTask,
					},
					{
						name: t("task_want_to_remove_no"),
						className: "btn-danger",
					},
				]}
			/>
			<Backdrop show={removing}>
				<Spinner size={120} centerVertically />
			</Backdrop>
			<div>
				<h2 className="task-internal__title">{activeTask.title}</h2>
				<p className="task-internal__description">
					{activeTask.description}
				</p>
			</div>
			<div className="divider divider--horizontal"></div>
			<div className="d-flex py-3">
				<div className="col-6">
					<TaskStatus data={activeTask} />
				</div>
				<div className="col-6">
					{t("task_creator")}{" "}
					<ObjectIcon username={activeTask.creator_full_name} />
				</div>
			</div>
			<div className="divider divider--horizontal"></div>
			<div className="d-flex py-3">
				<div className="col-6">
					{t("task_date_from")}
					<div className="d-flex align-items-start">
						<IconWrapper className="mr-2">
							<i className="fas fa-calendar"></i>
						</IconWrapper>
						{Boolean(activeTask.date_from) &&
							new Date(
								parseInt(activeTask.date_from)
							).toLocaleString("lt-LT")}
					</div>
				</div>
				<div className="col-6">
					{t("task_date_to")}
					<div className="d-flex align-items-start">
						<IconWrapper className="mr-2">
							<i className="fas fa-calendar"></i>
						</IconWrapper>
						{Boolean(activeTask.date_to) &&
							new Date(
								parseInt(activeTask.date_to)
							).toLocaleString("lt-LT")}
					</div>
				</div>
			</div>
			<div className="divider divider--horizontal"></div>
			{activeTask.object_name?.length > 0 && (
				<>
					<div className="d-flex py-3">
						<div className="col-6">
							{t("task_object")}
							<p className="mb-0">{activeTask.object_name}</p>
							<span className="text-gray">
								<i className="fas fa-map-marker-alt mr-2"></i>
								{activeTask.object_address}
							</span>
							{/* <div className="d-flex align-items-start">
							<IconWrapper className="mr-2">
								<i className="fas fa-calendar"></i>
							</IconWrapper>
							{Boolean(activeTask.date_from) &&
								new Date(
									parseInt(activeTask.date_from)
								).toLocaleString("lt-LT")}
						</div> */}
						</div>
					</div>
					{/* <div className="divider divider--horizontal"></div> */}
				</>
			)}
		</>
	);

	let bottomBody =
		activeTask.file?.length > 0 ||
		getTaskStatusName(activeTask) === STATUSES.Declined ||
		activeTask.user_id === user.userData.id ? (
			<>
				<div className="divider divider--horizontal mt-2"></div>
				{activeTask.file?.length > 0 && (
					<>
						<div className="active-task-files">
							{activeTask.file.split(",").map(fileUrl => (
								<ZoomableImage
									key={fileUrl}
									filename={fileUrl}
									zIndex={10003}
									document={true}
									column={true}
								/>
							))}
						</div>
						<div className="divider divider--horizontal"></div>
					</>
				)}

				{getTaskStatusName(activeTask) === STATUSES.Declined && (
					<div className="mt-2">
						{t("task_decline_comment")}: {activeTask.comment}
					</div>
				)}

				<div className="mt-3">
					{activeTask.user_id === user.userData.id && (
						<>
							{" "}
							{getTaskStatusName(activeTask) ===
								STATUSES.Created && (
								<div>
									<Button
										className="btn btn-wide btn-primary"
										onClick={acceptTask}
										loading={loading}
									>
										{t("task_accept_button_text")}
									</Button>
									<Button
										className="btn btn-wide btn-danger ml-3"
										onClick={() =>
											setDeclineTaskModal(true)
										}
										loading={loading}
									>
										{t("task_decline_button_text")}
									</Button>

									<Modal
										show={declineTaskModal}
										disableModal={() =>
											setDeclineTaskModal(false)
										}
										title={t("task_decline_button_text")}
										footerButtons={
											<Button
												onClick={() =>
													formSubmitButton.current.click()
												}
												className="btn-primary"
												loading={loading}
											>
												{t("task_decline_button_text")}
											</Button>
										}
									>
										<form onSubmit={declineTask}>
											<Input
												placeholder={t(
													"task_decline_comment"
												)}
												onChange={e =>
													setDeclineComment(
														e.target.value
													)
												}
												required
											/>
											<button
												ref={formSubmitButton}
												className="d-none"
											>
												Submit form
											</button>
										</form>
									</Modal>
								</div>
							)}
							{getTaskStatusName(activeTask) ===
								STATUSES.Accepted && (
								<Button
									className="btn btn-primary btn-wide"
									onClick={finishTask}
									loading={loading}
								>
									{t("task_finish_button_text")}
								</Button>
							)}
						</>
					)}
				</div>
			</>
		) : null;

	let isAssigned = Boolean(
		activeTask.channel?.assigners?.find(
			assigner => assigner.user_id === user.userData.id
		)
	);

	const doesHaveImages = Boolean(activeTask.image?.length);

	return (
		<div className="task-internal p-4">
			<div>
				<Link
					to={TASKS_PAGE_PATH}
					className="go-back-button empty-button h2"
				>
					<i className="fas fa-arrow-left mb-4"></i>
				</Link>
			</div>

			{isAssigned ? (
				<>
					<div className="d-flex">
						{doesHaveImages && (
							<div className="col-6">{imageSlider}</div>
						)}
						<div
							className={doesHaveImages ? "col-6 pl-4" : "col-12"}
						>
							{body}
						</div>
					</div>
					<div>{bottomBody}</div>
				</>
			) : (
				<>
					{imageSlider}
					{body}
					{bottomBody}
				</>
			)}

			{isAssigned && chat.activeSubchannels.length ? (
				<>
					{doesHaveImages ? <hr /> : <div className="mt-3" />}
					<TaskInternalChat subchannel={chat.activeSubchannels[0]} />
				</>
			) : null}
		</div>
	);
};

export default TaskInternal;
