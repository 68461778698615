import React, { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const RelativeDiv = styled.div`
	position: relative;
`;

const Backdrop = styled.div`
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.4);

	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 1.2rem;
	font-weight: 600;

	transition: 0.2s ease-in-out;
	opacity: 100;

	${({ active }) =>
		!active && "z-index: -1; opacity: 0; backdrop-filter: none;"}
`;

const FileDrop = ({ onDrop, className, children, ...props }) => {
	const [dragOver, setDragOver] = useState(0);
	const { t } = useTranslation();

	const onDropHandler = e => {
		e.preventDefault();
		setDragOver(0);
		if (onDrop) onDrop(e);
	};

	return (
		<RelativeDiv
			onDragEnter={() => setDragOver(dragOver => dragOver + 1)}
			onDragLeave={() =>
				setDragOver(dragOver =>
					dragOver > 0 ? dragOver - 1 : dragOver
				)
			}
			onDragOver={e => e.preventDefault()}
			onDrop={onDropHandler}
			className={classNames(className, "file-drop", {
				active: dragOver,
			})}
			{...props}
		>
			{children}
			<Backdrop active={dragOver}>{t("drop_files")}</Backdrop>
		</RelativeDiv>
	);
};

export default FileDrop;
