import React, { useEffect, useRef, useContext, memo } from "react";
import MeasurableElementsContext from "contexts/MeasurableElementsContext";

const MeasurableDiv = memo(({ name, children, staticMeasure, ...props }) => {
	const [, setElementsHeight] = useContext(MeasurableElementsContext);
	const el = useRef();
	const elHeight = useRef(0);

	useEffect(() => {
		if (staticMeasure && elHeight.current) return;
		if (el?.current && el.current.offsetHeight !== elHeight.current) {
			elHeight.current = el.current.offsetHeight;
			setElementsHeight(size => ({
				...size,
				[name]: elHeight.current,
			}));
		}
	}, [name, children, setElementsHeight, staticMeasure]);

	useEffect(() => {
		return function cleanup() {
			setElementsHeight(size => ({ ...size, [name]: 0 }));
			elHeight.current = 0;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setElementsHeight]);

	return (
		<div ref={el} {...props}>
			{children}
		</div>
	);
});

export default MeasurableDiv;
