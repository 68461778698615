import React from "react";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	// componentDidCatch(error, errorInfo) {
	//     logErrorToMyService(error, errorInfo);
	// }

	render() {
		if (this.state.hasError) {
			return (
				<div className="container-fluid mt-4 item-enter-done">
					<div className="d-flex flex-row align-items-center min-vh-100">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-12 text-center">
									<span className="display-1 d-block">
										Error
									</span>
									<div className="mb-4 lead">
										Something went wrong.
									</div>
									<a className="btn btn-link" href="/">
										Back to Home
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
