import React, { useState, useEffect, useReducer } from "react";
import { HashRouter as Router } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "../node_modules/react-notifications/lib/notifications.css";
import "../node_modules/react-datepicker/dist/react-datepicker.css";

import api from "api";
import UserContext from "./contexts/UserContext";
import TasksContext from "./contexts/TasksContext";
import IsMobileContext from "./contexts/IsMobileContext";
import MeasurableElementsContext from "./contexts/MeasurableElementsContext";
import LazyImageContext from "contexts/LazyImgContext";
import useWindowDimensions from "hooks/useWindowDimensions";
import LoadingWrapper from "components/core/LoadingWrapper";
import AppContent from "./AppContent";
import ChatWrapper from "components/ChatWrapper";

function reducer(state, action) {
	switch (action.type) {
		case "ADD_TO_LOADING":
			return {
				...state,
				loadingList: [...state.loadingList, action.payload],
			};
		case "REMOVE_TOP_FROM_LOADING":
			return { ...state, loadingList: state.loadingList.slice(1) };
		case "REMOVE_FROM_LOADING":
			return {
				...state,
				loadingList: state.loadingList.filter(
					src => src !== action.payload
				),
			};
		case "ADD_TO_CACHE":
			return { ...state, cache: { ...state.cache, ...action.payload } };
		case "RESET_LOADING_LIST":
			return { ...state, loadingList: [] };
		default:
			throw new Error();
	}
}

const App = () => {
	const [loading, setLoading] = useState(true);
	const [serverError, setServerError] = useState(false);
	const user = useState({ authorized: false, userData: {} });
	const tasks = useState({ list: [], additionalOffset: 0 });
	const elementsHeight = useState({ base: 0, subHeader: 0 });

	const lazyImagesState = useReducer(reducer, {
		loadingList: [],
		cache: {},
	});

	const { width } = useWindowDimensions();
	const isMobile = width < 700;

	useEffect(() => {
		if (user[0].userData?.id) return;
		const token = localStorage.getItem("token");
		if (!token) return setLoading(false);

		setLoading(true);
		api.get("/user")
			.then(response => {
				if (!response.data.error)
					user[1]({ authorized: true, userData: response.data.data });
				else localStorage.removeItem("token");
			})
			.catch(() => setServerError(true))
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user[0]]);

	if (serverError) {
		throw new Error("Server error");
	}

	return (
		<Router>
			<UserContext.Provider value={user}>
				<TasksContext.Provider value={tasks}>
					<MeasurableElementsContext.Provider value={elementsHeight}>
						<IsMobileContext.Provider value={isMobile}>
							<LazyImageContext.Provider value={lazyImagesState}>
								<ChatWrapper>
									<LoadingWrapper
										fetched={!loading}
										size={130}
										centerVertically
									>
										<>
											<AppContent />
										</>
									</LoadingWrapper>
									<NotificationContainer />
								</ChatWrapper>
							</LazyImageContext.Provider>
						</IsMobileContext.Provider>
					</MeasurableElementsContext.Provider>
				</TasksContext.Provider>
			</UserContext.Provider>
		</Router>
	);
};

export default App;
