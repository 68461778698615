import React from "react";
import { CSSTransition } from "react-transition-group";

import Spinner from "./Spinner";

const LoadingWrapper = ({ fetched, children, size, centerVertically }) => {
	return (
		<CSSTransition in={fetched} timeout={500} classNames="fade">
			{!fetched ? (
				<Spinner
					size={size}
					centerVertically={centerVertically}
					color={"#343a40"}
				/>
			) : (
				children
			)}
		</CSSTransition>
	);
};

export default LoadingWrapper;
