import * as imageConversion from "image-conversion";
import api from "api";

async function uploadImages(images, initialImagesStr = "") {
	const initialImages =
		!initialImagesStr || !initialImagesStr.length
			? []
			: initialImagesStr.split(",");

	const newImages = images.filter(image => !image.length);
	const presentImages = images.filter(image => initialImages.includes(image));
	const imagesToDelete = initialImages.filter(
		imageFileName => !images.includes(imageFileName)
	);

	let filenames = [...presentImages];
	let error = [];

	for (let index in newImages) {
		const compressedBlob = await imageConversion.compress(
			newImages[index],
			0.8
		);
		const compressedImage = new File(
			[compressedBlob],
			newImages[index].name.replace(/ /g, "_")
		);
		const fileUploadData = new FormData();
		fileUploadData.append("file", compressedImage);

		await api
			.post("/fileupload", fileUploadData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				if (response.data.error) error.push(response.data.error);
				else filenames.push(response.data.filename);
			});
	}

	for (let index in imagesToDelete) {
		await api
			.delete("/delete_image", {
				params: { image_name: imagesToDelete[index] },
			})
			.then(() => {
				// if (response.data.error)
			});
	}

	return [filenames.join(","), error.length ? error[0] : null];
}

export default uploadImages;
