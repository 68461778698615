import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import UserContext from "contexts/UserContext";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";
import MessageBubble from "./MessageBubble";
import SeenMessageText from "./SeenMessageText";
import Spinner from "components/core/Spinner";
import MessageDate from "./MessageDate";

const ChatMessages = ({
	setPage,
	fetchingMessages,
	activeSubchannel,
	isStickedChat,
	channel_id = "",
	setForwardModal,
}) => {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [scrollHeight, setScrollHeight] = useState(0);
	const [scrollToTop, setScrollToTop] = useState(false);
	const messagesList = useRef();

	const [, setTime] = useState(Date.now()); // FOR CHAT MESSAGE DATE LABELS UPDATION
	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 60 * 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (!messagesList?.current) return;
		if (scrollToTop) {
			messagesList.current.scrollTop =
				messagesList.current.scrollHeight - scrollHeight;
			setScrollToTop(false);
		} else
			messagesList.current.scrollTop = messagesList.current.scrollHeight;

		setScrollHeight(messagesList.current.scrollHeight);

		messagesList.current.onscroll = e => {
			if (e.target.scrollTop <= 0) {
				setPage(page => page + 1);
				setScrollToTop(true);
				return;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messagesList, activeSubchannel.messages]);

	useEffect(() => {
		if (!messagesList?.current) return;
		setScrollToTop(false);
	}, [activeSubchannel]);

	return (
		<FullScreenHeightWrapper
			ref={messagesList}
			className="messages-list pl-3 pr-3 py-3"
			elements={
				isStickedChat
					? [`messageInput${channel_id}`]
					: ["subHeader", "messageInput"]
			}
			maxValue={isStickedChat && "420px"}
			staticOffset={!isStickedChat ? 30 : 0}
		>
			<>
				{fetchingMessages && <Spinner color="#000" />}
				{activeSubchannel.messages?.length
					? activeSubchannel.messages.map(message => {
							const updatedAt = new Date(
								+message.updated_at
							).toLocaleString("lt-LT", {
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "2-digit",
								minute: "2-digit",
							});

							const isCurrentUser =
								message.sender_id === user.userData.id;

							return (
								<div key={message.id}>
									{activeSubchannel.unreadMessageId + 1 ===
										message.id && (
										<div className="new-messages text-center">
											<span className="bg-secondary text-secondary">
												{updatedAt}
											</span>
										</div>
									)}
									{(activeSubchannel.channel_object_id ||
										activeSubchannel.channel_task_id ||
										activeSubchannel.task_id) &&
										!isCurrentUser && (
											<span className="d-block font-weight-bold mt-1">
												{message.sender_name}
											</span>
										)}
									<MessageBubble
										data={message}
										presentUserId={user.userData.id}
										updatedAt={updatedAt}
										setForwardModal={setForwardModal}
									/>
									<MessageDate
										date={+message.updated_at}
										isCurrentUser={isCurrentUser}
									/>
									<SeenMessageText
										seenMessages={
											activeSubchannel.seenMessages
										}
										message={message}
										presentUserFullName={
											user.userData.full_name
										}
									/>
								</div>
							);
					  })
					: !fetchingMessages && (
							<p className="text-center">{t("no_messages")}</p>
					  )}
			</>
		</FullScreenHeightWrapper>
	);
};

export default ChatMessages;
