import React from "react";
import { useTranslation } from "react-i18next";

import IconWrapper from "./IconWrapper";
import { getTaskStatusName } from "utils/taskStatuses";

const taskFinish = {
	type: "success",
	icon: "fas fa-check",
};
const taskAccepted = {
	type: "success",
	icon: "fas fa-clock",
};
const taskDeclined = {
	type: "danger",
	icon: "fas fa-times",
};
const taskNotAccepted = {
	type: "warning",
	icon: "fas fa-info",
};

const statusesData = {
	tasks_finished: taskFinish,
	tasks_cell_finished: taskFinish,
	tasks_accepted: taskAccepted,
	tasks_cell_in_progress: taskAccepted,
	tasks_declined: taskDeclined,
	tasks_cell_declined: taskDeclined,
	tasks_not_accepted: taskNotAccepted,
	tasks_cell_need_accept: {
		type: "warning",
		icon: "fas fa-info",
	},
};

const TaskStatus = ({ status, data }) => {
	const { t } = useTranslation();

	let finalStatus = status || getTaskStatusName(data);

	if (!status && !data) return null;
	let statusData = statusesData[finalStatus];
	return (
		<div className={`text-${statusData.type} d-flex align-items-center`}>
			<IconWrapper type={statusData.type} className="mr-2">
				<i className={statusData.icon}></i>
			</IconWrapper>
			{t(finalStatus)}
		</div>
	);
};

export default TaskStatus;
