import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "api";

import { TASKS_PAGE_PATH } from "routes";
import LoadingWrapper from "components/core/LoadingWrapper";
import Alert from "components/core/Alert";
import ObjectIcon from "components/User";
import SubHeader from "components/SubHeader";
import FullScreenHeightWrapper from "components/FullScreenHeightWrapper";

const TaskHistory = ({ match }) => {
	const { t } = useTranslation();
	const [fetching, setFetching] = useState(true);
	const [history, setHistory] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		api.get("/task_history", { params: { task_id: match.params.id } })
			.then(response => {
				if (response.data.error) setError(response.data.error);
				else setHistory(response.data.data);
			})
			.catch(() => setError(t("error_unknown")))
			.finally(() => setFetching(false));
	}, [match.params.id, t]);

	return (
		<div className="history-wrapper bg-secondary">
			<div className="container-fluid">
				<SubHeader className="d-flex align-items-center">
					<Link
						to={`${TASKS_PAGE_PATH}/${match.params.id}`}
						className="btn btn-back"
					>
						<i className="fas fa-long-arrow-alt-left"></i>
					</Link>
					<h2>{t("tool_history_navigation_title")}</h2>
				</SubHeader>

				<FullScreenHeightWrapper>
					<LoadingWrapper fetched={!fetching} size={120}>
						<>
							{error ? (
								<Alert success={false}>{error}</Alert>
							) : (
								<>
									{!history.length && (
										<h4 className="text-center py-3">
											{t("task_list_empty")}
										</h4>
									)}
									{history
										.map((item, i) => {
											let statusText = `${t(
												"task_history_task_is"
											)} `;

											let left = [];
											let right = [];

											if (i === 0)
												statusText += t(
													"task_history_created_by"
												);
											else {
												if (
													(item.finish_date || 0) ===
														0 &&
													(item.finish_date || 0) > 0
												)
													statusText += t(
														"task_history_finished_by"
													);
												else if (
													(item.accept_date || 0) ===
														0 &&
													(item.accept_date || 0) > 0
												)
													statusText += t(
														"task_history_accepted_by"
													);
												else if (
													(item.declined_date ||
														0) === 0 &&
													(item.declined_date || 0) >
														0
												)
													statusText += t(
														"task_history_declined_by"
													);
												else
													statusText += t(
														"task_history_updated_by"
													);

												const lastItem = history[i - 1];

												if (
													lastItem.title !==
													item.title
												) {
													left.push(
														lastItem.title
															? lastItem.title
															: t("none")
													);
													right.push(
														item.title
															? item.title
															: t("none")
													);
												}
												if (
													lastItem.description !==
													item.description
												) {
													left.push(
														lastItem.description
															? lastItem.description
															: "Empty"
													);
													right.push(
														item.description
															? item.description
															: "Empty"
													);
												}
												if (
													lastItem.user_full_name !==
													item.user_full_name
												) {
													left.push(
														lastItem.user_full_name
															? lastItem.user_full_name
															: t("none")
													);
													right.push(
														item.user_full_name
															? item.user_full_name
															: t("none")
													);
												}
											}

											return (
												<div
													key={item.date}
													className="main-row"
												>
													<div className="col-3">
														<span className="subheading">
															{item.date
																? new Date(
																		parseInt(
																			item.date
																		)
																  ).toLocaleDateString(
																		"lt-LT"
																  )
																: ""}
														</span>
														<h3>
															{
																item.user_full_name
															}
														</h3>
													</div>
													<div className="col-3">
														<span className="subheading">
															{statusText}
														</span>
														<ObjectIcon
															username={
																item.history_creator_full_name
															}
														/>
													</div>
													<div className="col-6">
														{(left.length > 0 ||
															right.length >
																0) && (
															<div className="d-flex text-primary text-center">
																<div className="col-5 h4">
																	{left.map(
																		(
																			label,
																			i
																		) => (
																			<p
																				key={
																					i
																				}
																				className="my-1"
																			>
																				{
																					label
																				}
																			</p>
																		)
																	)}
																</div>
																<div className="col-2 h2 align-self-center">
																	<i className="fas fa-angle-double-right"></i>
																</div>
																<div className="col-5 h4">
																	{right.map(
																		(
																			label,
																			i
																		) => (
																			<p
																				key={
																					i
																				}
																				className="my-1"
																			>
																				{
																					label
																				}
																			</p>
																		)
																	)}
																</div>
															</div>
														)}
													</div>
												</div>
											);
										})
										.reverse()}
								</>
							)}
						</>
					</LoadingWrapper>
				</FullScreenHeightWrapper>
			</div>
		</div>
	);
};

export default TaskHistory;
