import React, { useContext, forwardRef } from "react";

import MeasurableElementsContext from "contexts/MeasurableElementsContext";

const FullScreenHeightWrapper = forwardRef(
	(
		{
			children,
			maxValue = "100vh",
			elements = ["subHeader"],
			staticOffset = 0,
			...props
		},
		ref
	) => {
		const [elementsHeight] = useContext(MeasurableElementsContext);

		const offset =
			elementsHeight.base +
			elements.reduce(
				(accum, currValue) => accum + (elementsHeight[currValue] || 0),
				0
			) +
			staticOffset;

		return (
			<div
				style={{
					height: `calc(${maxValue} - ${offset}px)`,
					overflow: "auto",
				}}
				ref={ref}
				{...props}
			>
				{children}
			</div>
		);
	}
);

export default FullScreenHeightWrapper;
