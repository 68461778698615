import React from "react";
import { Route, Redirect } from "react-router-dom";

const RouteWrapper = ({ user, route }) => {
	if (route.authorizedOnly && !user.authorized)
		return <Redirect to={{ pathname: "/login" }} />;
	if (route.unauthorizedOnly && user.authorized)
		return <Redirect to={{ pathname: "/" }} />;
	if (
		(route.role && route.role !== user.userData.role) ||
		(route.roles && !route.roles.includes(user.userData.role))
	) {
		return <Redirect to={{ pathname: "/" }} />;
	}

	return (
		<Route
			path={route.path}
			component={route.component}
			exact={route.exact}
		/>
	);
};

export default RouteWrapper;
