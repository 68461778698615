import React from "react";
// import { ReactSVG } from "react-svg";
import styled from "styled-components";
import classNames from "classnames";

const PlusCircleWrapper = styled.div`
	svg {
		height: auto;
		width: 4.1rem;
		${({ modalIcon }) => {
			if (modalIcon) {
				return `
			circle {
				fill: #E8F3FD;
			}
			path {
				stroke: #2189E6;
			}
		`;
			}
		}}
	}
`;

const PlusCircle = ({ className, modalIcon, ...props }) => {
	return (
		<PlusCircleWrapper
			modalIcon={modalIcon}
			className={classNames([
				{ "cursor-pointer": !modalIcon },
				className,
			])}
			{...props}
		>
			{/* <ReactSVG src="svg/plus.svg" /> */}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width="88.646"
				height="88.646"
				viewBox="0 0 88.646 88.646"
			>
				<defs>
					<filter
						id="Oval"
						x="0"
						y="0"
						width="88.646"
						height="88.646"
						filterUnits="userSpaceOnUse"
					>
						<feOffset input="SourceAlpha" />
						<feGaussianBlur stdDeviation="4" result="blur" />
						<feFlood floodColor="#2189e6" floodOpacity="0.165" />
						<feComposite operator="in" in2="blur" />
						<feComposite in="SourceGraphic" />
					</filter>
				</defs>
				<g id="Add" transform="translate(12 12)">
					<g
						transform="matrix(1, 0, 0, 1, -12, -12)"
						filter="url(#Oval)"
					>
						<circle
							id="Oval-2"
							date-name="Oval"
							cx="32.323"
							cy="32.323"
							r="32.323"
							transform="translate(12 12)"
							fill="#2189e6"
						/>
					</g>
					<g
						id="Cancel"
						transform="translate(32.323 20.337) rotate(45)"
					>
						<path
							id="Path_2"
							date-name="Path 2"
							d="M17.777,17.777,0,0"
							transform="translate(0.539 0.539)"
							fill="none"
							stroke="#fff"
							strokeLinecap="round"
							strokeMiterlimit="10"
							strokeWidth="4.27"
						/>
						<path
							id="Path_2-2"
							date-name="Path 2"
							d="M17.777,0,0,17.777"
							transform="translate(0.539 0.539)"
							fill="none"
							stroke="#fff"
							strokeLinecap="round"
							strokeMiterlimit="10"
							strokeWidth="4.27"
						/>
					</g>
				</g>
			</svg>
		</PlusCircleWrapper>
	);
};

export default PlusCircle;
