import React, { useState, useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "api";

import uploadImages from "utils/uploadImages";
import uploadFiles from "utils/uploadFiles";
import useForm from "hooks/useForm";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import ToolForm from "./ToolForm";

const EditToolModal = ({ show, disableModal, tool, setTool, setTools }) => {
	const { t } = useTranslation();
	const [images, setImages] = useState(
		tool?.image ? tool.image.split(",") : []
	);
	const [documents, setDocuments] = useState(
		tool?.file ? tool.file.split(",") : []
	);
	const [formData, setFormData, handleInput] = useForm(tool);
	const [saving, setSaving] = useState(false);
	const [togglingStatus, setTogglingStatus] = useState(false);
	const [error, setError] = useState(null);
	const formSubmitButton = useRef();

	useEffect(() => {
		setFormData(tool);
		setImages(tool?.image ? tool.image.split(",") : []);
		setDocuments(tool?.file ? tool.file.split(",") : []);
	}, [tool, show, setFormData, setImages]);

	const saveTool = async e => {
		e.preventDefault();

		if (saving) return;
		setSaving(true);
		setError(null);

		let [newImagesStr, error] = await uploadImages(images, formData.image);
		if (error) {
			setSaving(false);
			return setError(error);
		}
		const [newDocumentsStr, filesUploadError] = await uploadFiles(
			documents,
			formData.file
		);
		if (filesUploadError) {
			setSaving(false);
			return setError(filesUploadError);
		}

		let status = formData.status;
		if (tool.user_id !== formData.user_id) {
			status = formData.user_id > 0 ? "TAKEN" : "AVAILABLE";
		}

		let newToolData = {
			...formData,
			status,
			image: newImagesStr,
			file: newDocumentsStr,
		};
		if (!newToolData.object_id) newToolData.object_id = -1;

		api.put("/material", { material_id: formData.id, ...newToolData })
			.then(response => {
				setSaving(false);
				if (response.data.error) setError(response.data.error);
				else {
					setTools(tools =>
						tools.map(tool =>
							tool.id === formData.id ? newToolData : tool
						)
					);
					setTool(newToolData);
					disableModal();
				}
			})
			.catch(() => {
				NotificationManager.error(t("error_unknown"));
				setSaving(false);
			});
	};

	const toggleStatus = () => {
		if (togglingStatus) return;
		setTogglingStatus(true);

		const newStatus = tool?.status === "BROKEN" ? "IN_REPAIR" : "BROKEN";
		const toolObj = {
			...formData,
			status: newStatus,
			user_id: -1,
			user_full_name: "",
		};

		api.put("/material", {
			material_id: formData.id,
			...toolObj,
		})
			.then(response => {
				if (response.data.error)
					NotificationManager.error(response.data.error);
				else {
					setTools(tools =>
						tools.map(tool =>
							tool.id === formData.id ? toolObj : tool
						)
					);
					setTool(toolObj);
				}
			})
			.catch(() => NotificationManager.error(t("error_unknown")))
			.finally(() => setTogglingStatus(false));
	};

	return (
		<Modal
			title={t("edit_tool_navigation_title")}
			show={Boolean(show)}
			disableModal={disableModal}
			footerButtons={
				<>
					<Button
						onClick={toggleStatus}
						loading={togglingStatus}
						className="btn-danger mr-2"
					>
						{tool?.status === "BROKEN"
							? t("edit_tool_repairing_button_text")
							: t("edit_tool_broken_button_text")}
					</Button>
					<Link
						to={`/tools/${tool?.id}/history`}
						className="btn btn-secondary mr-2"
					>
						{t("tool_history_navigation_title")}
					</Link>
					<Button
						onClick={() => formSubmitButton?.current?.click()}
						loading={saving}
						className="btn-primary"
					>
						{t("edit_tool_update_button_text")}
					</Button>
				</>
			}
		>
			<ToolForm
				onSubmit={saveTool}
				handleInput={handleInput}
				formData={formData}
				formSubmitButton={formSubmitButton}
				setImages={setImages}
				setDocuments={setDocuments}
				error={error}
			/>
		</Modal>
	);
};

export default EditToolModal;
