import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const MINUTE_MILLIS = 60 * 1000;
const HOUR_MILLIS = MINUTE_MILLIS * 60;
const DAY_MILLIS = HOUR_MILLIS * 24;
const WEEK_MILLIS = DAY_MILLIS * 7;

function convertDateToWords(date, t) {
	let time = date;

	if (time < 1000000000000) {
		time *= 1000;
	}

	let now = +new Date();

	if (time > now || time <= 0) {
		return "";
	}

	let diff = now - time;
	if (diff < MINUTE_MILLIS) {
		return t("now");
	} else if (diff < HOUR_MILLIS) {
		return `${Math.round(diff / MINUTE_MILLIS)}${t("minutes")}`;
	} else if (diff < DAY_MILLIS) {
		return `${Math.round(diff / HOUR_MILLIS)}${t("hours")}`;
	} else if (diff < WEEK_MILLIS) {
		return `${Math.round(diff / DAY_MILLIS)}${t("days")}`;
	} else
		return new Date(+time).toLocaleString("lt-LT", {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		});
}

const MessageDate = ({ date, isCurrentUser }) => {
	const { t } = useTranslation();

	return (
		<div
			className={classNames(
				"message-date pr-2",
				isCurrentUser ? "text-right" : "text-left"
			)}
		>
			{convertDateToWords(date, t)}
		</div>
	);
};

export default MessageDate;
